import React, { useEffect, useState } from 'react';
import useInsightsStore from '../../store/useInsightsStore';
import {
  Card,
  Grid,
  Col,
  LineChart,
  BarChart,
  Tab,
  TabGroup,
  TabList,
  BarList,
  TabPanels,
  TabPanel,
  DonutChart,
  Legend,
} from '@tremor/react';
import ReactSelect from '../../components/ReactSelect';
import SearchBar from '../../components/Search';
import { getServiceLabel } from '../../utils/common';
import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';

type ChartData = {
  dates: string[]; // Array of dates (assuming they're strings)
  values: Record<string, any>[]; // Array of objects where each key maps to a value (adjust as per your actual structure)
};

type KeyMapping = Record<string, string>; // Maps original keys to new keys

const formatChartData = (
  data: ChartData = { dates: [], values: [] },
  keyMapping: KeyMapping = {}
) => {
  const { dates, values } = data;

  // Helper function to sum Lead Gen and Ecommerce if it's an object
  const sumValues = (colorValue: any) => {
    if (typeof colorValue === 'object') {
      return (colorValue['Lead Gen'] || 0) + (colorValue['Ecommerce'] || 0); // Handle undefined keys
    }
    return colorValue; // Return the value if it's not an object
  };

  return dates?.map((date, index) => {
    const value = values[index] || {}; // Provide a default empty object if value is undefined
    const formattedItem = { date };

    // Loop through keyMapping to rename and handle missing keys
    Object?.keys(keyMapping)?.forEach((originalKey) => {
      const newKey = keyMapping[originalKey];
      formattedItem[newKey] =
        value[originalKey] !== undefined ? sumValues(value[originalKey]) : 0;
    });

    return formattedItem;
  });
};

const PerformanceInsights = () => {
  const { fetchInsights, insights, loading } = useInsightsStore(
    (state) => state
  );
  const [leadGenData, setLeadGenData] = useState([]);
  const [ecommerceData, setEcommerceData] = useState([]);
  // today minus 1 month
  const todayDate = moment().subtract(1, 'months').format('YYYY-MM');

  const handleFetchInsights = () => {
    const payload = {
      report_date: todayDate,
    };
    fetchInsights(payload);
  };

  useEffect(() => {
    handleFetchInsights();
  }, []);

  const statusKeys = {
    green: 'green',
    amber: 'amber',
    red: 'red',
  };

  const serviceKey = {
    seo_google: 'Google SEO',
    ppc_google: 'Google Ads',
    social_meta: 'Meta',
  };

  const statusData = formatChartData(insights.status, statusKeys);
  const serviceData = formatChartData(insights.service, serviceKey);

  const Top10leadGenData = insights?.category?.top10?.filter(
    (item) => item.category_type === 'Lead Gen'
  );

  const Top10ecommerceData = insights?.category?.top10?.filter(
    (item) => item.category_type === 'Ecommerce'
  );

  const UnderPerformanceLeadGen = insights?.category?.low10?.filter(
    (item) => item.category_type === 'Lead Gen'
  );

  const UnderPerformanceEcommerce = insights?.category?.low10?.filter(
    (item) => item.category_type === 'Ecommerce'
  );

  const dataFormatter = (number: number) =>
    Intl.NumberFormat('us').format(number).toString();

  const categoryData = insights?.category?.category;

  const formatDataForCategory = (categoryName, filterCategory) => {
    const category = categoryData?.[categoryName]; // Safely access category data

    // If the category doesn't exist, return an empty array
    if (!category) {
      return [];
    }

    // If filterCategory is empty, sum all colors
    if (filterCategory === '') {
      const allColors = ['green', 'amber', 'red'];
      const totalValues = {};

      // Loop through all colors and sum values for each key
      allColors.forEach((color) => {
        if (category[color]) {
          // Ensure the color exists
          Object.keys(category[color]).forEach((key) => {
            if (!totalValues[key]) {
              totalValues[key] = 0;
            }
            totalValues[key] += category[color][key];
          });
        }
      });

      // Return the summed values
      return Object.keys(totalValues).map((key) => ({
        name: getServiceLabel(key)?.label,
        value: totalValues[key],
      }));
    }

    // If the selected color exists, return its data
    if (
      category[filterCategory] &&
      Object.keys(category[filterCategory]).length > 0
    ) {
      return Object.keys(category[filterCategory]).map((key) => ({
        name: getServiceLabel(key)?.label,
        value: category[filterCategory][key],
      }));
    }

    // If the selected color doesn't exist, fall back to summing all colors
    const allColors = ['green', 'amber', 'red'];
    const totalValues = {};

    // Loop through all colors and sum values for each key
    allColors.forEach((color) => {
      if (category[color]) {
        // Ensure the color exists
        Object.keys(category[color]).forEach((key) => {
          if (!totalValues[key]) {
            totalValues[key] = 0;
          }
          totalValues[key] += category[color][key];
        });
      }
    });

    // Return the summed values
    return Object.keys(totalValues).map((key) => ({
      name: getServiceLabel(key)?.label,
      value: totalValues[key],
    }));
  };

  // Update the leadGenData in useEffect
  useEffect(() => {
    if (categoryData) {
      // Only run if categoryData is available
      const leadGenData = formatDataForCategory('Lead Gen', '');
      setLeadGenData(leadGenData); // Set leadGenData state
      const ecommerceData = formatDataForCategory('Ecommerce', '');
      setEcommerceData(ecommerceData);
    }
  }, [insights, categoryData]);

  return (
    <div className="pl-10 pr-3 py-4">
      <Card className="dark:bg-[#222732]">
        <Grid
          numItems={1}
          numItemsSm={1}
          numItemsMd={1}
          numItemsLg={2}
          className="gap-4"
        >
          <Col>
            <Card>
              <Grid
                className="gap-4 mb-4"
                numItems={1}
                numItemsSm={1}
                numItemsMd={3}
                numItemsLg={3}
              >
                <Col numColSpanLg={2}>
                  <SearchBar
                    value=""
                    placeholder="Elliot Rhodes"
                    width="w-full"
                  />
                </Col>
                <Col numColSpanLg={1}>
                  <ReactSelect
                    value={`All Services`}
                    options={[
                      { value: 'green', label: 'Green' },
                      { value: 'amber', label: 'Amber' },
                      { value: 'red', label: 'Red' },
                    ]}
                    handleOnChange={(selectedOption: any) => {
                      console.log(selectedOption);
                    }}
                    placeholder="All Status"
                  />
                </Col>
              </Grid>
              {loading ? (
                <div className="w-full min-h-[320px] h-full flex justify-center items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <LineChart
                  className="h-80"
                  data={statusData}
                  index="date"
                  categories={['green', 'amber', 'red']}
                  colors={['green', 'amber', 'rose']}
                  valueFormatter={dataFormatter}
                  startEndOnly
                  showAnimation
                  yAxisWidth={30}
                  onValueChange={(v) => console.log(v)}
                />
              )}
            </Card>
          </Col>
          <Col>
            <Card>
              <div className="mb-4">
                <SearchBar value="" placeholder="Search ..." width="w-full" />
              </div>
              {loading ? (
                <div className="w-full min-h-[320px] h-full flex justify-center items-center">
                  <Icon name="Loading" size={60} />
                </div>
              ) : (
                <BarChart
                  className=""
                  data={serviceData}
                  showAnimation
                  startEndOnly
                  index="date"
                  categories={['Google SEO', 'Google Ads', 'Meta']}
                  colors={['amber', '#000', '#0029FF', '#009EFF']}
                  valueFormatter={dataFormatter}
                  yAxisWidth={48}
                />
              )}
            </Card>
          </Col>
        </Grid>
      </Card>
      <Grid
        numItems={1}
        numItemsSm={1}
        numItemsMd={2}
        numItemsLg={4}
        className="gap-4 mt-4"
      >
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Top Performance
            </div>
            <div className="mt-4">
              <ReactSelect
                value={`All Services`}
                options={[
                  { value: 'green', label: 'Google Ads' },
                  { value: 'amber', label: 'Google SEO' },
                  { value: 'red', label: 'Meta' },
                  { value: 'red', label: 'Microsoft Ads' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Services"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center  items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <TabGroup defaultIndex={1}>
                <TabList variant="line">
                  <Tab value="1">Lead Gen</Tab>
                  <Tab value="2">Ecommerce</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {Top10leadGenData && Top10leadGenData.length > 0 ? (
                      Top10leadGenData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[8vw] py-2">
                            <p className="font-[600] text-sm dark:text-white truncate">
                              {data.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div className="text-[#3CCB7F] text-sm">
                            {data.revenue}
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full text-center pt-20 text-gray-500">
                        No data
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {Top10ecommerceData && Top10ecommerceData.length > 0 ? (
                      Top10ecommerceData.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[8vw] py-2">
                            <p className="font-[600] text-sm dark:text-white truncate">
                              {data.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div className="text-[#3CCB7F] text-sm">
                            {data.revenue}
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full text-center pt-20 text-gray-500">
                        No data
                      </div>
                    )}
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            )}
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px]  h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Under Performance
            </div>
            <div className="mt-4">
              <ReactSelect
                value={`All Services`}
                options={[
                  { value: 'green', label: 'Google Ads' },
                  { value: 'amber', label: 'Google SEO' },
                  { value: 'red', label: 'Meta' },
                  { value: 'red', label: 'Microsoft Ads' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Services"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <TabGroup defaultIndex={1}>
                <TabList variant="line">
                  <Tab value="1">Lead Gen</Tab>
                  <Tab value="2">Ecommerce</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    {UnderPerformanceLeadGen &&
                    UnderPerformanceLeadGen.length > 0 ? (
                      UnderPerformanceLeadGen.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[8vw] py-2">
                            <p className="font-[600] text-sm dark:text-white truncate">
                              {data.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div className="text-[#3CCB7F] text-sm">
                            {data.revenue}
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full text-center pt-20 text-gray-500">
                        No data
                      </div>
                    )}
                  </TabPanel>
                  <TabPanel>
                    {UnderPerformanceEcommerce &&
                    UnderPerformanceEcommerce.length > 0 ? (
                      UnderPerformanceEcommerce.map((data, index) => (
                        <div
                          key={index}
                          className="border-b flex w-full justify-between items-center"
                        >
                          <div className="w-[8vw] py-2">
                            <p className="font-[600] text-sm dark:text-white truncate">
                              {data.name}
                            </p>
                            <p className="font-light text-[#6B7280] text-sm">
                              {getServiceLabel(data.service)?.label}
                            </p>
                          </div>
                          <div className="text-[#3CCB7F] text-sm">
                            {data.revenue}
                          </div>
                          <div className="text-xs text-[#001C44] bg-[#F2F4FF] p-1 rounded">
                            {data.category_type}
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="w-full text-center pt-20 text-gray-500">
                        No data
                      </div>
                    )}
                  </TabPanel>
                </TabPanels>
              </TabGroup>
            )}
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Lead Gen
            </div>
            <div className={'mt-4'}>
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <BarList
                data={leadGenData}
                className="mx-auto max-w-sm mt-4"
                showAnimation
                color="teal"
              />
            )}
          </Card>
        </Col>
        <Col>
          <Card className="p-[24px] h-full dark:bg-[#222732]">
            <div className="font-[700] text-[18px] dark:text-white">
              Ecommerce
            </div>
            <div className="mt-4">
              <ReactSelect
                value={``}
                options={[
                  { value: 'green', label: 'Green' },
                  { value: 'amber', label: 'Amber' },
                  { value: 'red', label: 'Red' },
                ]}
                handleOnChange={(selectedOption: any) => {
                  console.log(selectedOption);
                }}
                placeholder="All Status"
              />
            </div>
            {loading ? (
              <div className="w-full min-h-[200px] h-full flex justify-center items-center">
                <Icon name="Loading" size={60} />
              </div>
            ) : (
              <>
                <div className="flex justify-center mt-4">
                  <DonutChart
                    data={ecommerceData}
                    variant="pie"
                    valueFormatter={dataFormatter}
                    onValueChange={(v) => console.log(v)}
                    showAnimation
                  />
                </div>
                <div className="mt-4 w-full flex justify-center">
                  <Legend
                    categories={['Google SEO', 'Google Ads', 'Meta Ads']}
                    colors={['blue', 'cyan', 'indigo', 'violet', 'fuchsia']}
                    className="max-w-[14vw] text-center"
                  />
                </div>
              </>
            )}
          </Card>
        </Col>
      </Grid>
    </div>
  );
};

export default PerformanceInsights;
