// performance.tsx
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import TansStackTableV2 from '../../components/TansStackTableV2';
import ReactSelect from '../../components/ReactSelect';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import useVisibilityStore from '../../store/useVisibilityStore';
import { formatToCurrency, formatNumber } from '../../utils/common';
import CustomModal from '../../components/CustomModal';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import PerformanceInsightsModal from '../../components/Modals/Performance/insightsModal';
import usePerformanceStore from '../../store/usePerformanceStore';
import PerformanceModal from '../../components/Modals/Performance';
import MultiFilterPerformanceModal from '../../components/MultiFilterPerformanceModal';
import 'react-datepicker/dist/react-datepicker.css';
import ExportModal from '../../components/Modals/Performance/Export';
import { generateSeoColumns } from './seoColumn';
import { generatePpcColumns } from './ppcColumns';
import { generateMetaColumns } from './metaColumns';
import { calculatePerformancePercentage } from '../../utils/performance';
import Alert from '../../components/Alert';
import { toast } from 'react-toastify';

const clientsOptions = [
  { value: 'all', label: 'All Clients' },
  { value: 'my', label: 'My Client' },
];

const campaignOptions = [
  { value: 'Lead Gen', label: 'Lead Gen' },
  { value: 'Ecommerce', label: 'Ecommerce' },
];

const cycleOptions = [
  { value: 'all', label: 'All Cycles' },
  { value: 'Cycle 1', label: 'Cycle 1' },
  { value: 'Cycle 2', label: 'Cycle 2' },
];

const statusOptions = [
  { value: 'red', label: 'Red' },
  { value: 'amber', label: 'Amber' },
  { value: 'green', label: 'Green' },
];

const integrationOptions = [
  { value: 'all', label: 'All Integrations' },
  { value: 'missing_integration', label: 'Missing Integration' },
  { value: 'integrated', label: 'Integrated' },
];

interface PerformanceFilterStorage {
  type: string;
  options: any[]; // Adjust this type according to your actual data structure
}

const serviceTypeOptions = [
  { value: 'seo_google', label: 'Google SEO' },
  { value: 'ppc_google', label: 'Google Ads' },
  { value: 'social_meta', label: 'Meta' },
];

const Performance: React.FC = () => {
  const [page, setPage] = useState(1);
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [openInsightModal, setOpenInsightModal] = useState(false);
  const { performance, fetchPerformance, fetchSummary, regeneratePerformance } =
    usePerformanceStore();
  const [searchText, setSearchText] = useState('');
  const [rowState, setRowState] = useState(true);
  const [mount, setMount] = useState(false);
  const [limit, setLimit] = useState(10);

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const { openVisibility, setOpenVisibility, performanceVisibilityOption } =
    useVisibilityStore();
  const [sortingDirection, setSortingDirection] = useState('');
  const [sortingColumn, setSortingColumn] = useState('');
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const [openEditModal, setOpenEditModal] = useState(false);
  const lastMonth = moment().subtract(1, 'month').format('MM-yyyy');
  const maxDate = moment(lastMonth, 'MM-yyyy').endOf('month').toDate();
  const today = new Date();
  const [date, setDate] = useState(today);
  const [, setPerformanceFilter] = useState(null);

  const [performanceFilterStorage, setPerformanceFilterStorage] =
    useState<PerformanceFilterStorage>({
      type: '',
      options: [],
    });

  today.setMonth(today.getMonth() - 1);

  const [type, setType] = useState<{
    label: string;
    value: string;
  } | null>({ label: 'Google SEO', value: 'seo_google' });

  const [, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [cycle, setCycle] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [status, setStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [integration, setIntegration] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [campaignType, setCampaignType] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [clients, setClients] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const location = useLocation();

  const handleVisibilityClick = () => {
    setOpenVisibility(!openVisibility);
  };

  useEffect(() => {
    // Sync initial state
    const usersStorage = localStorage.getItem('performance-filter-storage');
    setPerformanceFilterStorage(JSON.parse(usersStorage));

    const handleStorage = (event) => {
      const getPerformanceFilter = JSON.parse(
        event?.target?.localStorage?.getItem('performance-filter-storage')
      );

      setPerformanceFilterStorage(getPerformanceFilter);
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  useEffect(() => {
    if (mount) {
      handlePerformanceFetch(false, false); // Fetch performance on sort change
    }
  }, [sortingDirection, sortingColumn]);

  useEffect(() => {
    if (mount) {
      handlePerformanceFetch(true, true); // Fetch summary on mount, clear the page
    }
  }, [
    date,
    page,
    clients,
    integration,
    cycle,
    status,
    limit,
    type,
    performanceFilterStorage,
    campaignType,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const dateFromUrl = queryParams.get('date');
    // const sortDirectionFromURL = queryParams.get('sortDirection');
    // const sortColumnFromURL = queryParams.get('sortColumn');
    const pageUrl = queryParams.get('page');
    const cycleFromUrl = queryParams.get('cycle');
    const integrationFromUrl = queryParams.get('integration');
    const clientsFromUrl = queryParams.get('client');
    const statusFromUrl = queryParams.get('status');
    const serviceFromUrl = queryParams.get('service');
    const serviceTypeOption = serviceTypeOptions.find(
      (option) => option.value === serviceFromUrl
    );
    const cycleOption = cycleOptions.find(
      (option) => option.value === cycleFromUrl
    );
    const statusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const integrationOption = integrationOptions.find(
      (option) => option.value === integrationFromUrl
    );
    const clientOption = clientsOptions.find(
      (option) => option.value === clientsFromUrl
    );
    const dateUrl = dateFromUrl ? new Date(dateFromUrl) : date;

    setMount(true);
    // setSortingColumn(sortColumnFromURL);
    // setSortingDirection(sortDirectionFromURL);
    setPage(Number(pageUrl));
    setCycle(cycleOption);
    setType(serviceTypeOption);
    setIntegration(integrationOption);
    setClients(clientOption);
    setDate(dateUrl);
    setStatus(statusOption);
  }, [location]);

  useEffect(() => {
    if (searchText === '' && mount) {
      handlePerformanceFetch();
    }
  }, [searchText]);

  const handlePerformanceFetch = (isFetchSummary?: boolean, clear = false) => {
    let formattedPerformanceClientFilter = [];
    let formattedPerformanceUserFilter = [];

    if (performanceFilterStorage?.type === 'clients') {
      formattedPerformanceClientFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option) => option.id)
        : [];
      formattedPerformanceUserFilter = []; // Ensure users is empty
    } else {
      formattedPerformanceUserFilter = performanceFilterStorage?.options
        ? performanceFilterStorage.options.map((option) => option.id)
        : [];
      formattedPerformanceClientFilter = []; // Ensure clients is empty
    }
    const payload = {
      search: searchText,
      page: page,
      limit: limit,
      all: clients?.value === 'all',
      service: type?.value ? type?.value : 'seo_google',
      campaign_type: campaignType?.value,
      report_date: moment(date).format('YYYY-MM'),
      sortBy: sortingColumn || ' ',
      sortOrder: sortingDirection || ' ',
      cycle: cycle?.value || '',
      integration: integration?.value,
      role: '',
      status: status?.value === 'all' ? '' : status?.value,
      exclude: [],
      clients: formattedPerformanceClientFilter,
      users: formattedPerformanceUserFilter,
    };

    setPerformanceFilter(payload);

    if (isFetchSummary) {
      fetchSummary(payload);
    }

    fetchPerformance(payload);
    setRowState(false);
  };

  const handleRegeneratePerformance = (clients: any, type: string) => {
    const payload = {
      service: type,
      clientPerformanceId: clients?._id,
    };

    regeneratePerformance(payload)
      .then(() => handlePerformanceFetch())
      .then(() => {
        toast.success(
          <Alert
            icon=""
            title="Regenerate Complete"
            message="Performance has been regenerated successfully"
          />,
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      })
      .catch((e: any) => {
        toast.error(
          <Alert icon="" title="ERROR MESSAGE!" message={e.message} />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  const handleSearch = () => {
    try {
      handlePerformanceFetch(true);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSortTable = (sortColumn, sortDirection) => {
    let newSortDirection = '';

    if (sortingDirection === '') {
      if (sortDirection === 'desc') {
        newSortDirection = 'asc';
      } else {
        newSortDirection = 'desc';
      }
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortingColumn !== sortColumn) {
      newSortDirection = 'asc';
    } else if (sortingDirection === 'asc') {
      newSortDirection = 'desc';
    } else if (sortingDirection === 'desc') {
      newSortDirection = null;
    } else if (sortingDirection === null) {
      newSortDirection = 'asc';
    }

    if (sortDirection === null) {
      setSortingColumn('');
    }

    setSortingDirection(newSortDirection);
    setSortingColumn(sortColumn);

    // const newUrl = new URL(window.location.href);
    // newUrl.searchParams.set('sortDirection', newSortDirection);
    // newUrl.searchParams.set('sortColumn', sortColumn);
    // window.history.pushState({}, '', newUrl);
  };

  const getNumberOfPositive = (item, type) => {
    let comparisons = [];
    if (type === 'ppc_google') {
      comparisons = [
        performanceVisibilityOption['googleAds.clicks'] && {
          current: formatNumber(item?.googleAds?.clicks || 0),
          previous: formatNumber(item?.previous_month?.googleAds?.clicks || 0),
        },
        performanceVisibilityOption['googleAds.conversionRate'] && {
          current: formatNumber(item?.googleAds?.conversion_rate || 0),
          previous: formatNumber(
            item?.previous_month?.googleAds?.conversion_rate || 0
          ),
        },
        performanceVisibilityOption['googleAds.conversions'] && {
          current: formatNumber(item?.googleAds?.conversions || 0),
          previous: formatNumber(
            item?.previous_month?.googleAds?.conversions || 0
          ),
        },
        performanceVisibilityOption['googleAds.conversions_value'] && {
          current: formatNumber(item?.googleAds?.conversions_value || 0),
          previous: formatNumber(
            item?.previous_month?.googleAds?.conversions_value || 0
          ),
        },
        performanceVisibilityOption['googleAds.costPerConversion'] && {
          current: formatNumber(item?.googleAds?.costPerConversion || 0),
          previous: formatNumber(
            item?.previous_month?.googleAds?.costPerConversion || 0
          ),
        },
        performanceVisibilityOption['googleAds.cpc'] && {
          current: formatNumber(item?.googleAds?.cpc || 0),
          previous: formatNumber(item?.previous_month?.googleAds?.cpc || 0),
        },
        performanceVisibilityOption['googleAds.ctr'] && {
          current: formatNumber(item?.googleAds?.ctr || 0),
          previous: formatNumber(item?.previous_month?.googleAds?.ctr || 0),
        },
        performanceVisibilityOption['googleAds.impressions'] && {
          current: formatNumber(item?.googleAds?.impressions || 0),
          previous: formatNumber(
            item?.previous_month?.googleAds?.impressions || 0
          ),
        },
        performanceVisibilityOption['googleAds.roas'] && {
          current: formatNumber(item?.googleAds?.roas || 0),
          previous: formatNumber(item?.previous_month?.googleAds?.roas || 0),
        },
      ].filter(Boolean);
    } else if (type === 'social_meta') {
      comparisons = [
        performanceVisibilityOption['meta.impressions'] && {
          current: formatNumber(item?.meta?.impressions || 0),
          previous: formatNumber(item?.previous_month?.meta?.impressions || 0),
        },
        performanceVisibilityOption['meta.reach'] && {
          current: formatNumber(item?.meta?.reach || 0),
          previous: formatNumber(item?.previous_month?.meta?.reach || 0),
        },
        performanceVisibilityOption['meta.frequency'] && {
          current: formatNumber(item?.meta?.frequency || 0),
          previous: formatNumber(item?.previous_month?.meta?.frequency || 0),
        },
        performanceVisibilityOption['meta.cpm'] && {
          current: formatNumber(item?.meta?.cpm || 0),
          previous: formatNumber(item?.previous_month?.meta?.cpm || 0),
        },
        performanceVisibilityOption['meta.link_clicks'] && {
          current: formatNumber(item?.meta?.link_clicks || 0),
          previous: formatNumber(item?.previous_month?.meta?.link_clicks || 0),
        },
        performanceVisibilityOption['meta.ctr'] && {
          current: formatNumber(item?.meta?.ctr || 0),
          previous: formatNumber(item?.previous_month?.meta?.ctr || 0),
        },
        performanceVisibilityOption['meta.website_leads'] && {
          current: formatNumber(item?.meta?.website_leads || 0),
          previous: formatNumber(
            item?.previous_month?.meta?.website_leads || 0
          ),
        },
        performanceVisibilityOption['meta.cpl'] && {
          current: formatNumber(item?.meta?.cpl || 0),
          previous: formatNumber(item?.previous_month?.meta?.cpl || 0),
        },
        performanceVisibilityOption['meta.cvr'] && {
          current: formatNumber(item?.meta?.cvr || 0),
          previous: formatNumber(item?.previous_month?.meta?.cvr || 0),
        },
        performanceVisibilityOption['meta.revenue'] && {
          current: formatNumber(item?.meta?.revenue || 0),
          previous: formatNumber(item?.previous_month?.meta?.revenue || 0),
        },
        performanceVisibilityOption['meta.roas'] && {
          current: formatNumber(item?.meta?.roas || 0),
          previous: formatNumber(item?.previous_month?.meta?.roas || 0),
        },
      ].filter(Boolean);
    } else {
      comparisons = [
        performanceVisibilityOption['ga4.organic_revenue'] && {
          current: formatNumber(item?.ga4?.organic_revenue || 0),
          previous: formatNumber(
            item?.previous_month?.ga4?.organic_revenue || 0
          ),
        },
        performanceVisibilityOption['ga4.organic_conversions'] && {
          current: formatNumber(item?.ga4?.organic_conversions || 0),
          previous: formatNumber(
            item?.previous_month?.ga4?.organic_conversions || 0
          ),
        },
        performanceVisibilityOption['ga4.organic_new_users'] && {
          current: formatNumber(item?.ga4?.organic_new_users || 0),
          previous: formatNumber(
            item?.previous_month?.ga4?.organic_new_users || 0
          ),
        },
        performanceVisibilityOption['ga4.new_users'] && {
          current: formatNumber(item?.ga4?.new_users || 0),
          previous: formatNumber(item?.previous_month?.ga4?.new_users || 0),
        },
        performanceVisibilityOption['gsc.clicks'] && {
          current: formatNumber(item?.gsc?.clicks || 0),
          previous: formatNumber(item?.previous_month?.gsc?.clicks || 0),
        },
        performanceVisibilityOption['gsc.impressions'] && {
          current: formatNumber(item?.gsc?.impressions || 0),
          previous: formatNumber(item?.previous_month?.gsc?.impressions || 0),
        },
        performanceVisibilityOption['ahrefs.top_1_keywords_reach'] && {
          current: formatNumber(item?.ahrefs?.top_1_keywords_reach || 0),
          previous: formatNumber(
            item?.previous_month?.ahrefs?.top_1_keywords_reach || 0
          ),
        },
        performanceVisibilityOption['ahrefs.top_3_keywords_reach'] && {
          current: formatNumber(item?.ahrefs?.top_3_keywords_reach || 0),
          previous: formatNumber(
            item?.previous_month?.ahrefs?.top_3_keywords_reach || 0
          ),
        },
        performanceVisibilityOption['ahrefs.top_10_keywords_reach'] && {
          current: formatNumber(item?.ahrefs?.top_10_keywords_reach || 0),
          previous: formatNumber(
            item?.previous_month?.ahrefs?.top_10_keywords_reach || 0
          ),
        },
        performanceVisibilityOption['return.conversions'] && {
          current: formatNumber(item?.return?.conversions || 0),
          previous: formatNumber(
            item?.previous_month?.return?.conversions || 0
          ),
        },
        performanceVisibilityOption['return.ecommerce'] && {
          current: formatNumber(item?.return?.ecommerce || 0),
          previous: formatNumber(item?.previous_month?.return?.ecommerce || 0),
        },
        performanceVisibilityOption['ahrefs.refdomains'] && {
          current: formatNumber(item?.ahrefs?.refdomains || 0),
          previous: formatNumber(item?.previous_month?.ahrefs?.refdomains || 0),
        },
      ].filter(Boolean);
    }

    return comparisons.reduce(
      (acc, { current, previous }) => acc + (current > previous ? 1 : 0),
      0
    );
  };

  const formattedDataMemo = useMemo(() => {
    const formatData = (data) => {
      if (type?.value === 'ppc_google') {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'googleAds.clicks': item?.googleAds?.clicks || 0,
            'googleAds.conversion_rate': item?.googleAds?.conversion_rate || 0,
            'googleAds.conversions': item?.googleAds?.conversions || 0,
            'googleAds.conversions_value':
              item?.googleAds?.conversions_value || 0,
            'googleAds.costPerConversions':
              item?.googleAds?.costPerConversions || 0,
            'googleAds.cpc': item?.googleAds?.cpc || 0,
            'googleAds.ctr': item?.googleAds?.ctr || 0,
            'googleAds.impressions': item?.googleAds?.impressions || 0,
            'googleAds.roas': item?.googleAds?.roas || 0,
          };

          // item?.previous_month?.

          const previousPeriod = {
            'googleAds.clicks': item?.previous_month?.clicks || 0,
            'googleAds.conversion_rate':
              item?.previous_month?.conversion_rate || 0,
            'googleAds.conversions': item?.previous_month?.conversions || 0,
            'googleAds.conversions_value':
              item?.previous_month?.conversions_value || 0,
            'googleAds.costPerConversions':
              item?.previous_month?.costPerConversions || 0,
            'googleAds.cpc': item?.previous_month?.cpc || 0,
            'googleAds.ctr': item?.previous_month?.ctr || 0,
            'googleAds.impressions': item?.previous_month?.impressions || 0,
            'googleAds.roas': item?.previous_month?.roas || 0,
          };

          const performancePercentage = calculatePerformancePercentage(
            currentPeriod,
            previousPeriod,
            item?.category_type,
            performanceVisibilityOption,
            'ppc_google'
          );
          const numberOfPositives = getNumberOfPositive(item, 'ppc_google');

          return {
            client: item,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            hsAm: formatNumber(item?.hs_am || 0),
            hsCons: formatNumber(item?.hs_cons || 0),
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfPositive: numberOfPositives,
            },
            clicks: {
              current: item?.googleAds?.clicks
                ? formatNumber(item?.googleAds?.clicks || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.clicks
                ? formatNumber(item?.previous_month?.googleAds?.clicks || 0)
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.clicks,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversionRate: {
              current: item?.googleAds?.conversion_rate
                ? formatNumber(item?.googleAds?.conversion_rate || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversion_rate
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversion_rate || 0
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversionRate,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversions: {
              current: item?.googleAds?.conversions
                ? formatNumber(item?.googleAds?.conversions || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversions
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversions || 0
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            conversions_value: {
              current: item?.googleAds?.conversions_value
                ? formatNumber(item?.googleAds?.conversions_value || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.conversions_value
                ? formatNumber(
                    item?.previous_month?.googleAds?.conversions_value || 0
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.conversions_value,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            costPerConversion: {
              current: item?.googleAds?.costPerConversion
                ? formatNumber(item?.googleAds?.costPerConversion || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.costPerConversion
                ? formatNumber(
                    item?.previous_month?.googleAds?.costPerConversion || 0
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.costPerConversion,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpc: {
              current: item?.googleAds?.cpc
                ? formatNumber(item?.googleAds?.cpc || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.cpc
                ? formatNumber(item?.previous_month?.googleAds?.cpc || 0)
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.cpc,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            ctr: {
              current: item?.googleAds?.ctr
                ? formatNumber(item?.googleAds?.ctr || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.ctr
                ? formatNumber(item?.previous_month?.googleAds?.ctr || 0)
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.ctr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            impressions: {
              current: item?.googleAds?.impressions
                ? formatNumber(item?.googleAds?.impressions || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.impressions
                ? formatNumber(
                    item?.previous_month?.googleAds?.impressions || 0
                  )
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.impressions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            roas: {
              current: item?.googleAds?.roas
                ? formatNumber(item?.googleAds?.roas || 0)
                : 0,
              message: item?.googleAds?.message,
              previous: item?.previous_month?.googleAds?.roas
                ? formatNumber(item?.previous_month?.googleAds?.roas || 0)
                : '0',
              dailyMetrics: item?.googleAds?.dailyMetrics?.roas,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
          };
        });
        return tableData;
      } else if (type?.value === 'social_meta') {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'meta.impressions': item?.meta?.impressions || 0,
            'meta.reach': item?.meta?.reach || 0,
            'meta.frequency': item?.meta?.frequency || 0,
            'meta.cpm': item?.meta?.cpm || 0,
            'meta.link_clicks': item?.meta?.link_clicks || 0,
            'meta.cpc': item?.meta?.cpc || 0,
            'meta.ctr': item?.meta?.ctr || 0,
            'meta.website_leads': item?.meta?.website_leads || 0,
            'meta.cpl': item?.meta?.cpl || 0,
            'meta.cvr': item?.meta?.cvr || 0,
            'meta.revenue': item?.meta?.revenue || 0,
            'meta.roas': item?.meta?.roas || 0,
          };

          // item?.previous_month?.

          const previousPeriod = {
            'meta.impressions': item?.previous_month?.impressions || 0,
            'meta.reach': item?.previous_month?.reach || 0,
            'meta.frequency': item?.previous_month?.frequency || 0,
            'meta.cpm': item?.previous_month?.cpm || 0,
            'meta.link_clicks': item?.previous_month?.link_clicks || 0,
            'meta.cpc': item?.previous_month?.cpc || 0,
            'meta.ctr': item?.previous_month?.ctr || 0,
            'meta.website_leads': item?.previous_month?.website_leads || 0,
            'meta.cpl': item?.previous_month?.cpl || 0,
            'meta.cvr': item?.previous_month?.cvr || 0,
            'meta.revenue': item?.previous_month?.revenue || 0,
            'meta.roas': item?.previous_month?.roas || 0,
          };

          const performancePercentage = calculatePerformancePercentage(
            currentPeriod,
            previousPeriod,
            item?.category_type,
            performanceVisibilityOption,
            'social_meta'
          );
          const numberOfPositives = getNumberOfPositive(item, 'social_meta');
          return {
            client: item,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            hsAm: formatNumber(item?.hs_am || 0),
            hsCons: formatNumber(item?.hs_cons || 0),
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfPositive: numberOfPositives,
            },
            impressions: {
              current: item?.meta?.impressions
                ? formatNumber(item?.meta?.impressions || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.impressions
                ? formatNumber(item?.previous_month?.meta?.impressions || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.impressions,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            reach: {
              current: item?.meta?.reach
                ? formatNumber(item?.meta?.reach || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.reach
                ? formatNumber(item?.previous_month?.meta?.reach || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.reach,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            frequency: {
              current: item?.meta?.frequency
                ? formatNumber(item?.meta?.frequency || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.frequency
                ? formatNumber(item?.previous_month?.meta?.frequency || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.frequency,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpm: {
              current: item?.meta?.cpm ? formatNumber(item?.meta?.cpm || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cpm
                ? formatNumber(item?.previous_month?.meta?.cpm || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cpm,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            link_clicks: {
              current: item?.meta?.link_clicks
                ? formatNumber(item?.meta?.link_clicks || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.link_clicks
                ? formatNumber(item?.previous_month?.meta?.link_clicks || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.link_clicks,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            ctr: {
              current: item?.meta?.ctr ? formatNumber(item?.meta?.ctr || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.ctr
                ? formatNumber(item?.previous_month?.meta?.ctr || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.ctr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            website_leads: {
              current: item?.meta?.website_leads
                ? formatNumber(item?.meta?.website_leads || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.website_leads
                ? formatNumber(item?.previous_month?.meta?.website_leads || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.website_leads,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cpl: {
              current: item?.meta?.cpl ? formatNumber(item?.meta?.cpl || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cpl
                ? formatNumber(item?.previous_month?.meta?.cpl || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cpl,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            cvr: {
              current: item?.meta?.cvr ? formatNumber(item?.meta?.cvr || 0) : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.cvr
                ? formatNumber(item?.previous_month?.meta?.cvr || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.cvr,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            revenueMeta: {
              current: item?.meta?.revenue
                ? formatNumber(item?.meta?.revenue || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.revenue
                ? formatNumber(item?.previous_month?.meta?.revenue || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.revenue,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            roas: {
              current: item?.meta?.roas
                ? formatNumber(item?.meta?.roas || 0)
                : 0,
              message: item?.meta?.message,
              previous: item?.previous_month?.meta?.roas
                ? formatNumber(item?.previous_month?.meta?.roas || 0)
                : '0',
              dailyMetrics: item?.meta?.dailyMetrics?.roas,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
          };
        });

        return tableData;
      } else {
        const tableData = data?.map((item) => {
          const currentPeriod = {
            'ga4.new_users': item?.ga4?.new_users || 0,
            'ga4.organic_new_users': item?.ga4?.organic_new_users || 0,
            'ga4.organic_revenue': item?.ga4?.organic_revenue || 0,
            'ga4.organic_conversions': item?.ga4?.organic_conversions || 0,
            'gsc.clicks': item?.gsc?.clicks || 0,
            'gsc.impressions': item?.gsc?.impressions || 0,
            'ahrefs.top_3_keywords_reach':
              item?.ahrefs?.top_3_keywords_reach || 0,
            'ahrefs.top_10_keywords_reach':
              item?.ahrefs?.top_10_keywords_reach || 0,
            'ahrefs.top_1_keywords_reach':
              item?.ahrefs?.top_1_keywords_reach || 0,
            'ahrefs.refdomains': item?.ahrefs?.refdomains || 0,
            'return.conversions': item?.return?.conversions || 0,
            'return.ecommerce': item?.return?.ecommerce || 0,
          };

          const previousPeriod = {
            'ga4.new_users': item?.previous_month?.ga4?.new_users || 0,
            'ga4.organic_new_users':
              item?.previous_month?.ga4?.organic_new_users || 0,
            'ga4.organic_revenue':
              item?.previous_month?.ga4?.organic_revenue || 0,
            'ga4.organic_conversions':
              item?.previous_month?.ga4?.organic_conversions || 0,
            'gsc.clicks': item?.previous_month?.gsc?.clicks || 0,
            'gsc.impressions': item?.previous_month?.gsc?.impressions || 0,
            'ahrefs.top_3_keywords_reach':
              item?.previous_month?.ahrefs?.top_3_keywords_reach || 0,
            'ahrefs.top_10_keywords_reach':
              item?.previous_month?.ahrefs?.top_10_keywords_reach || 0,
            'ahrefs.top_1_keywords_reach':
              item?.previous_month?.ahrefs?.top_1_keywords_reach || 0,
            'ahrefs.refdomains': item?.previous_month?.ahrefs?.refdomains || 0,
            'return.conversions':
              item?.previous_month?.return?.conversions || 0,
            'return.ecommerce': item?.previous_month?.return?.ecommerce || 0,
          };

          const performancePercentage = calculatePerformancePercentage(
            currentPeriod,
            previousPeriod,
            item?.category_type,
            performanceVisibilityOption,
            'seo_google'
          );
          const numberOfPositives = getNumberOfPositive(item, 'seo_google');
          return {
            client: item,
            revenue: formatToCurrency(item?.revenue || 0),
            consultant: {
              name: item?.consultant || 'N/A',
              color:
                item?.health_status?.find((obj: any) =>
                  obj?.role.includes('con')
                )?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) =>
                  obj?.role.includes('con')
                )?.name || 'N/A',
            },
            account_manager: {
              name: item?.account_manager || 'N/A',
              color:
                item?.health_status?.find((obj: any) => obj?.role === 'am')
                  ?.val || 'gray',
              hsName:
                item?.health_status.find((obj: any) => obj?.role === 'am')
                  ?.name || 'N/A',
            },
            startDate: item?.start_date || 'N/A',
            hsAm: formatNumber(item?.hs_am || 0),
            hsCons: formatNumber(item?.hs_cons || 0),
            performance_date: item?.report_date,
            cycle: item?.cycle,
            service: item?.service,
            performance: {
              value: performancePercentage,
              calculated: item?.calculated_fields,
              type: item?.category_type,
              numberOfpositive: numberOfPositives,
            },

            organicRevenue: {
              current: item?.ga4?.organic_revenue
                ? formatNumber(item?.ga4?.organic_revenue || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_revenue
                ? formatNumber(item?.previous_month?.ga4?.organic_revenue || 0)
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_revenue,
              type: item?.category_type,
              clientName: item?.client?.name,
            },
            organicConversions: {
              current: item?.ga4?.organic_conversions
                ? formatNumber(item?.ga4?.organic_conversions || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_conversions
                ? formatNumber(
                    item?.previous_month?.ga4?.organic_conversions || 0
                  )
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_conversions,
              clientName: item?.client?.name,
            },
            organicUsers: {
              current: item?.ga4?.organic_new_users
                ? formatNumber(item?.ga4?.organic_new_users || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.organic_new_users
                ? formatNumber(
                    item?.previous_month?.ga4?.organic_new_users || 0
                  )
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.organic_new_users,
              clientName: item?.client?.name,
            },
            newUsers: {
              current: item?.ga4?.new_users
                ? formatNumber(item?.ga4?.new_users || 0)
                : 0,
              message: item?.notes?.ga4,
              previous: item?.previous_month?.ga4?.new_users
                ? formatNumber(item?.previous_month?.ga4?.new_users || 0)
                : '0',
              dailyMetrics: item?.ga4?.dailyMetrics?.all_new_users,
              clientName: item?.client?.name,
            },
            clicks: {
              current: item?.gsc?.clicks
                ? formatNumber(item?.gsc?.clicks || 0)
                : 0,
              message: item?.notes?.gsc,
              previous: item?.previous_month?.gsc?.clicks
                ? formatNumber(item?.previous_month?.gsc?.clicks || 0)
                : '0',
              dailyMetrics: item?.gsc?.dailyMetrics?.clicks,
              clientName: item?.client?.name,
            },
            impressions: {
              current: item?.gsc?.impressions
                ? formatNumber(item?.gsc?.impressions || 0)
                : 0,
              message: item?.notes?.gsc,
              previous: item?.previous_month?.gsc?.impressions
                ? formatNumber(item?.previous_month?.gsc?.impressions || 0)
                : '0',
              dailyMetrics: item?.gsc?.dailyMetrics?.impressions,
              clientName: item?.client?.name,
            },
            ahrefs_top_01: {
              current: item?.gsc?.top_1_keywords_reach
                ? formatNumber(item?.ahrefs?.top_1_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_1_keywords_reach || 0
              ),
            },
            ahrefsTop03: {
              current: item?.gsc?.top_3_keywords_reach
                ? formatNumber(item?.ahrefs?.top_3_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_3_keywords_reach || 0
              ),
            },
            ahrefs_top_10: {
              current: item?.gsc?.top_10_keywords_reach
                ? formatNumber(item?.ahrefs?.top_10_keywords_reach || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: formatNumber(
                item?.previous_month?.ahrefs?.top_10_keywords_reach || 0
              ),
            },
            conversions_return: {
              current: item?.return?.conversions
                ? formatNumber(item?.return?.conversions || 0)
                : 0,
              message: item?.notes?.return,
              previous: item?.previous_month?.return?.conversions
                ? formatNumber(
                    item?.previous_month?.return?.conversions || 0,
                    false,
                    4
                  )
                : '0',
            },
            ecommerce_return: {
              current: item?.return?.ecommerce
                ? formatNumber(item?.return?.ecommerce || 0)
                : 0,
              message: item?.notes?.return,
              previous: item?.previous_month?.return?.ecommerce
                ? formatNumber(
                    item?.previous_month?.return?.ecommerce || 0,
                    false,
                    4
                  )
                : '0',
              type: item?.category_type,
            },

            referring_domain: {
              current: item?.ahrefs?.refdomains
                ? formatNumber(item?.ahrefs?.refdomains || 0)
                : 0,
              message: item?.notes?.ahrefs,
              previous: item?.previous_month?.ahrefs?.refdomains
                ? formatNumber(item?.previous_month?.ahrefs?.refdomains || 0)
                : '0',
            },
          };
        });

        return tableData;
      }
    };
    const formattedData = formatData(performance.data);
    return formattedData;
  }, [performance.data, performanceVisibilityOption, type]);

  const editClientPerformance = (item: any) => {
    setSelectedPerformance(item);
    setOpenEditModal(true);
  };

  const generateColumns = (type, options) => {
    if (type === 'seo_google') {
      return generateSeoColumns(options);
    } else if (type === 'social_meta') {
      return generateMetaColumns(options);
    } else {
      return generatePpcColumns(options);
    }
  };

  const columns = useMemo(
    () =>
      generateColumns(type?.value ? type?.value : 'seo_google', {
        sortingColumn,
        sortingDirection,
        editClientPerformance,
        performanceVisibilityOption,
        handleRegeneratePerformance,
      }),
    [sortingColumn, sortingDirection, editClientPerformance]
  );

  const handleFilterChange = (selectedOption: any, actionMeta: string) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'status') {
      // setStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    // if (actionMeta === 'role') {
    //   setRole(selectedOption);
    //   newUrl.searchParams.set('role', selectedOption.value);
    // }
    if (actionMeta === 'type') {
      setSelectedType(selectedOption);
      newUrl.searchParams.set('type', selectedOption.value);
    }
    if (actionMeta === 'date') {
      setDate(selectedOption);
      newUrl.searchParams.set('date', moment(selectedOption).format('YYYY-MM'));
    }
    if (actionMeta === 'cycle') {
      setCycle(selectedOption);
      newUrl.searchParams.set('cycle', selectedOption.value);
    }
    if (actionMeta === 'client') {
      setClients(selectedOption);
      newUrl.searchParams.set(
        'all',
        selectedOption.value === 'all' ? 'true' : 'false'
      );
    }
    if (actionMeta === 'users') {
      setClients(selectedOption);
      newUrl.searchParams.set('client', selectedOption.value);
    }
    if (actionMeta === 'integration') {
      setIntegration(selectedOption);
      newUrl.searchParams.set('integration', selectedOption.value);
    }

    if (actionMeta === 'campaign') {
      setCampaignType(selectedOption);
      newUrl.searchParams.set('campaign_type', selectedOption.value);
    }

    if (actionMeta === 'service') {
      setType(selectedOption);
      newUrl.searchParams.set('service', selectedOption.value);
    }

    window.history.pushState({}, '', newUrl);
  };

  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden min-h-[93dvh] rounded-md dark:bg-[#191919] font-inter scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D]">
        <div className="flex items-center justify-between mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-[13] rounded-t-lg">
          <div className="flex flex-wrap items-center gap-2 sm:gap-4 relative">
            {/* <SearchBarDropdown performanceFilter={performanceFilter} /> */}
            <div
              className={`flex ${performanceFilterStorage?.options?.length > 0 ? 'justify-start' : 'justify-center text-[#525252]'} items-center gap-x-1 p-1 z-20 cursor-pointer`}
            >
              <div className="z-20">
                <MultiFilterPerformanceModal
                  handleFilterChange={handleFilterChange}
                  isOpen={openFilterModal}
                  onClose={() => setOpenFilterModal(false)}
                  formattedDataMemo={formattedDataMemo}
                  cycle={cycle}
                  clients={clients}
                  integration={integration}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  handleSearch={handleSearch}
                  performanceFilterStorage={performanceFilterStorage}
                  date={date}
                />
              </div>
            </div>
            {/* <SearchBar
                value={searchText}
                onChange={setSearchText}
                onSearch={handleSearch}
                placeholder="Search by client or user "
              /> */}
            <div className="font-inter w-[100px]">
              <DatePicker
                selected={moment(date, 'YYYY-MM').toDate()}
                onChange={(date) => {
                  if (date !== null) {
                    handleFilterChange(date, 'date');
                  }
                }}
                dateFormat="MMM yyyy"
                maxDate={maxDate}
                showMonthYearPicker
                className="react-datepicker__month-container text-center h-[38px] border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
              />
            </div>
            {/* <div>
              <ReactSelect
                options={roleOptions}
                value={role}
                placeholder="All Roles"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'role')
                }
              />
            </div> */}
            {/* <div>
              <ReactSelect
                options={typesOptions}
                value={selectedType}
                placeholder="All Type"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'type')
                }
              />
            </div> */}
            <div>
              <ReactSelect
                options={campaignOptions}
                value={campaignType}
                placeholder="All Campaign"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'campaign')
                }
              />
            </div>
            {/* <div>
              <ReactSelect
                options={cycleOptions}
                value={cycle}
                placeholder="All Cycles"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'cycle')
                }
              />
            </div> */}
            <div>
              <ReactSelect
                options={clientsOptions}
                value={clients}
                placeholder="All Clients"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'client')
                }
              />
            </div>
            <div>
              <ReactSelect
                options={integrationOptions}
                value={integration}
                placeholder="All Integrations"
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'integration')
                }
              />
            </div>
            <div className="max-w-[300px]">
              <ReactSelect
                options={serviceTypeOptions}
                value={type}
                handleOnChange={(selectedOption: any) =>
                  handleFilterChange(selectedOption, 'service')
                }
                placeholder="All Services"
              />
            </div>
          </div>
          <div className="hidden sm:flex items-center gap-2">
            <div className="flex gap-x-8 items-center">
              {/* <button
                className="p-2 rounded-md border"
                onClick={() => setShowExportModal(true)}
              >
                <Icon
                  name="Download"
                  size={16}
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button> */}
              <button
                className="flex items-center py-2 px-3.5 gap-2 rounded-lg border border-[#808EA2]"
                onClick={handleVisibilityClick}
              >
                <span className="text-[#001C44] text-sm font-semibold dark:text-white">
                  Columns
                </span>
                <Icon
                  name="offEye"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </button>
            </div>
          </div>
        </div>
        <TansStackTableV2
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={performance?.total_pages}
          page={page}
          setPage={setPage}
          loading={performance?.loading}
          rowState={rowState}
          setRowState={setRowState}
          totalDocs={performance?.total_docs}
          stickyColumn="name"
          sortData={handleSortTable}
          limit={limit}
          setLimit={setLimit}
        />
      </div>

      <CustomModal
        open={openInsightModal}
        onClose={() => setOpenInsightModal(false)}
      >
        <PerformanceInsightsModal onClose={() => setOpenInsightModal(false)} />
      </CustomModal>
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <PerformanceModal
          onClose={() => setOpenEditModal(false)}
          clientPerformance={selectedPerformance}
          handleFetchReports={handlePerformanceFetch}
          type={type?.value}
        />
      </CustomModal>
      <CustomModal
        open={showExportModal}
        onClose={() => {
          setRowState(false);
          setShowExportModal(false);
        }}
      >
        <ExportModal
          closeModal={() => {
            setRowState(false);
            setShowExportModal(false);
          }}
          selectDate={date}
          cycle={cycle}
          clients={clients}
          integration={integration}
        />
      </CustomModal>
    </div>
  );
};

export default Performance;
