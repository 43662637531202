import { requests } from './ApiService';

export const Performance = {
  getPerformance: (filters) =>
    requests.get(
      `/api/v1/client_performance${toQueryString({
        report_date: filters.report_date,
        page: filters.page,
        limit: filters.limit,
        search: filters.search,
        cycle: filters.cycle,
        role: filters.role,
        client: filters.client,
        service: filters.service,
        integration: filters.integration,
        sort_by: filters.sortBy,
        sort_order: filters.sortOrder,
        status: filters.status,
        clients: filters.clients,
        users: filters.users,
        category_type: filters.campaign_type,
      })}`
    ),
  getSummary: (filters) =>
    requests.get(
      `/api/v1/client_performance_summary${toQueryString({
        report_date: filters.report_date,
        cycle: filters.cycle,
        role: filters.role,
        service: filters.service,
        client: filters.client,
        integration: filters.integration,
        exclude: filters.exclude,
      })}`
    ),
  regeneratePerformance: (filters) =>
    requests.patch(`/api/v1/client_performance/regenerate`, {
      service: filters.service,
      clientPerformanceId: filters.clientPerformanceId,
    }),
};

// performance unique to format users and clients
const toQueryString = (params: { [key: string]: any }) =>
  Object.keys(params)
    .filter(
      (key) =>
        params[key] !== undefined &&
        params[key] !== null &&
        params[key] !== 'all'
    )
    .map((key, index) => {
      if (
        key === 'clients' ||
        key === 'users'
        // ||
        // key === 'clientPerformanceIds'
      ) {
        if (Array.isArray(params[key])) {
          return params[key]
            .map(
              (value) =>
                `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(value)}`
            )
            .join('&');
        }
        return `${index === 0 ? '?' : '&'}${key}[]=${encodeURIComponent(params[key])}`;
      }
      return `${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(params[key])}`;
    })
    .join('');
