import Icon from '../../assets/icons/SvgComponent';
import Tooltip from '../../components/HelpTooltip';
import { getStatusBadgeColor } from '../../utils/common';
import ImageIcon from '../../components/ImageIcon';
import { Link } from 'react-router-dom';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  handleOpenEditModal?: any;
  setLinkItem?: any;
}

// Function to generate columns
export const generateColumns = ({
  sortingColumn,
  sortingDirection,
  handleOpenEditModal,
  setLinkItem,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client Name</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]">
          <div>
            <Tooltip content={props.getValue()?.name} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[170px] sm:max-w-[15vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
            </Tooltip>
            <div className="text-xs max-w-[170px] sm:max-w-[15vw] truncate dark:text-white">
              {props.getValue()?.domain}
            </div>
          </div>
        </div>
      );
    },
  },
  // account_manager column
  {
    id: 'am',
    header: () => (
      <div className="w-[160px] sm:w-[6vw] ">
        <div className="flex w-[160px] sm:w-[6vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">AM</div>
          {sortingColumn === 'am' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'am',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[6vw] flex justify-center">
        <Tooltip position="right" content={props.getValue()}>
          <div
            className={`border-white border rounded-full dark:border-gray-700`}
          >
            <ImageIcon data={props.getValue()} size={8} textSize={'xs'} />
          </div>
        </Tooltip>
      </div>
    ),
  },
  // consultant column
  {
    id: 'consultant',
    header: () => (
      <div className="w-[160px] sm:w-[6vw] ">
        <div className="flex w-[160px] sm:w-[6vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Cons</div>
          {sortingColumn === 'consultant' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'consultant',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[6vw] flex justify-center">
        <Tooltip position="right" content={props.getValue()}>
          <div
            className={`border-white border rounded-full dark:border-gray-700`}
          >
            <ImageIcon data={props.getValue()} size={8} textSize={'xs'} />
          </div>
        </Tooltip>
      </div>
    ),
  },
  // anchor text column
  {
    id: 'anchorText',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Anchor Text</div>
          {sortingColumn === 'anchorText' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'anchorText',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex justify-center font-inter font-[700] text-sm text-[#001C44] dark:text-gray-300 text-xs">
          {props.getValue()}
        </div>
      </div>
    ),
  },
  // dr text column
  {
    id: 'DR',
    header: () => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex w-[160px] sm:w-[10vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">DR</div>
          {sortingColumn === 'DR' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'dr',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex flex-col gap-2 justify-center items-center font-inter font-[400] text-[10px] text-[#001C44] dark:text-gray-300">
          <div className="bg-[#568bff] text-white px-1 rounded">
            <span className="font-bold">REQUESTED:</span>
            {` `}
            {props.getValue()?.requested}
          </div>
          <div className="bg-[#ffa251] text-white px-1 rounded">
            <span className="font-bold">ADJUSTED:</span>
            {` `}
            {props.getValue()?.adjusted}
          </div>
          <div className="bg-[#2ed39c] text-white px-1 rounded">
            <span className="font-bold">DELIVERED:</span>
            {` `}
            {props.getValue()?.delivered}
          </div>
        </div>
      </div>
    ),
  },
  // target column
  {
    id: 'target',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Target</div>
          {sortingColumn === 'target' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'target',
    cell: (props: any) => (
      <>
        {!props?.getValue() || props?.getValue() === 'N/A' ? (
          <div className="w-[160px] sm:w-[16vw] flex justify-center items-center text-xs">
            N/A
          </div>
        ) : (
          <Link
            to={props?.getValue()}
            className="w-[160px] sm:w-[16vw] flex items-center"
            target="blank"
          >
            <div className="text-xs justify-center sm:max-w-[16vw] truncate font-inter font-[400] text-[#0029FF] dark:text-gray-300">
              {props?.getValue()}
            </div>
            <div>
              <Icon name="ArrowUpRight" size={16} color="#0029FF" />
            </div>
          </Link>
        )}
      </>
    ),
  },
  // link column
  {
    id: 'link',
    header: () => (
      <div className="w-[160px] sm:w-[16vw] ">
        <div className="flex w-[160px] sm:w-[16vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Link</div>
          {sortingColumn === 'link' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'link',
    cell: (props: any) => (
      <>
        {!props?.getValue() || props?.getValue() === 'N/A' ? (
          <div className="w-[160px] sm:w-[16vw] flex justify-center items-center text-xs">
            N/A
          </div>
        ) : (
          <Link
            to={props?.getValue()}
            className="w-[160px] sm:w-[16vw] flex items-center"
            target="blank"
          >
            <div className="text-xs justify-center sm:max-w-[16vw] truncate font-inter font-[400] text-[#0029FF] dark:text-gray-300">
              {props?.getValue()}
            </div>
            <div>
              <Icon name="ArrowUpRight" size={16} color="#0029FF" />
            </div>
          </Link>
        )}
      </>
    ),
  },
  // status column
  {
    id: 'status',
    header: () => (
      <div className="w-[160px] sm:w-[10vw] ">
        <div className="flex w-[160px] sm:w-[10vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Status</div>
          {sortingColumn === 'status' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'link',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[10vw] flex justify-center">
        <div
          className={`text-xs ${
            props?.getValue() !== 'N/A'
              ? getStatusBadgeColor('delivered')?.color
              : getStatusBadgeColor('open')?.color
          } p-1 px-2 rounded-full flex gap-2 items-center cursor-pointer dark:text-white w-fit`}
        >
          <div
            className={`w-2 h-2 ${
              props?.getValue() !== 'N/A'
                ? getStatusBadgeColor('delivered')?.bullet
                : getStatusBadgeColor('open')?.bullet
            }} rounded-full`}
          />
          {props?.getValue() !== 'N/A'
            ? getStatusBadgeColor('delivered')?.text
            : getStatusBadgeColor('open')?.text}
        </div>
      </div>
    ),
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    accessorKey: 'view',
    cell: (props: any) => (
      <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
        <div
          className="cursor-pointer"
          onClick={() => {
            setLinkItem(props.getValue());
            handleOpenEditModal();
          }}
        >
          <Icon name="edit" />
        </div>
      </div>
    ),
  },
];
