import { requests } from './ApiService';
import { toQueryString } from '../utils/common';
import { Config } from '../config';
const API_ROOT = Config.LINK_BRIEF_API;

export const LinkBrief = {
  getBriefItems: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
      })}&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
  getBriefItem: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
        client_id: filters.id,
      })}&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
  getAggregatedBriefItems: (filters: any) =>
    requests.get(
      `/api/link/brief/items${toQueryString({
        page: filters.page,
        limit: filters.limit,
      })}&aggregate=true&sort=createdAt&order=asc`,
      {},
      API_ROOT
    ),
  getLinkBriefItemsOptions: () => requests.get(`/api/link/brief`, {}, API_ROOT),
  getSearchLinkItems: (filters: any) =>
    requests.get(
      `/api/links${toQueryString({
        page: filters.page,
        limit: filters.limit,
        client_id: filters.client_id,
        search: filters.search,
        brief_id: filters.brief_id,
        status: filters.status,
        sort_by: filters.sort_by,
        sort_order: filters.sort_order,
      })}`,
      { filters },
      API_ROOT
    ),
};
