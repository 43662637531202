export const calculatePerformancePercentage = (
  currentPeriod: any,
  previousPeriod: any,
  type: any,
  performanceVisibilityOption: any,
  service: string
) => {
  // Define the metrics for each service
  const seoMetrics = [
    'ga4.new_users',
    'ga4.organic_new_users',
    'ga4.organic_revenue',
    'ga4.organic_conversions',
    'gsc.clicks',
    'gsc.impressions',
    'ahrefs.top_3_keywords_reach',
    'ahrefs.top_10_keywords_reach',
    'ahrefs.top_1_keywords_reach',
    'ahrefs.refdomains',
    'return.conversions',
    'return.ecommerce',
  ];

  const ppcMetrics = [
    'googleAds.clicks',
    'googleAds.conversionRate',
    'googleAds.conversions',
    'googleAds.conversions_value',
    'googleAds.costPerConversion',
    'googleAds.cpc',
    'googleAds.ctr',
    'googleAds.impressions',
    'googleAds.roas',
  ];

  const metaMetrics = [
    'meta.impressions',
    'meta.reach',
    'meta.frequency',
    'meta.cpm',
    'meta.link_clicks',
    'meta.ctr',
    'meta.website_leads',
    'meta.cpl',
    'meta.cvr',
    'meta.revenue',
    'meta.roas',
  ];

  // Select metrics based on service
  let allMetrics;
  if (service === 'seo_google') {
    allMetrics = seoMetrics;
  } else if (service === 'ppc_google') {
    allMetrics = ppcMetrics;
  } else if (service === 'social_meta') {
    allMetrics = metaMetrics;
  } else {
    // Default to an empty array if service is unknown
    allMetrics = [];
  }

  // Filter out the metrics based on type
  allMetrics =
    type === 'Lead Gen'
      ? allMetrics.filter(
          (field) =>
            field !== 'return.ecommerce' &&
            field !== 'ga4.organic_revenue' &&
            field !== 'ga4.paid_revenue'
        )
      : allMetrics;

  // Filter out the metrics to exclude
  const metricsToCompare = allMetrics.filter(
    (metric) => performanceVisibilityOption[metric]
  );

  let positiveCount = 0;

  // Compare each metric in the filtered list with tolerance
  metricsToCompare.forEach((metric) => {
    const currentValue = currentPeriod[metric];
    const previousValue = previousPeriod[metric];

    if (currentValue > previousValue) {
      positiveCount += 1;
    }
  });

  // Calculate the performance percentage
  const performancePercentage =
    metricsToCompare.length > 0
      ? (positiveCount / metricsToCompare.length) * 100
      : 0;

  return performancePercentage;
};

export const getDeltaTypeAndColor = (current, previous) => {
  // Remove commas and convert to numbers
  const currentNumber = Number(current?.toString()?.replace(/,/g, ''));
  const previousNumber = Number(previous?.toString()?.replace(/,/g, ''));

  const deltaType =
    currentNumber > previousNumber
      ? 'ArrowUpLeft'
      : currentNumber === previousNumber
        ? 'ArrowRight'
        : 'ArrowDownLeft';

  const color =
    currentNumber > previousNumber
      ? '#3CCB7F'
      : currentNumber === previousNumber
        ? '#FF692E'
        : '#FF0000';

  return { deltaType, color };
};
