import { create } from 'zustand';
// import Api from '../api';
import { devtools } from 'zustand/middleware';
import Api from '../api';

interface IGoalStore {
  insights: any;
  goalsGraph: any;
  message: '';
  error: false;
  loading: false;
  errorMessages: '';
  fetchInsights: (data: any) => void;
}

const useInsightsStore = create<IGoalStore>(
  devtools((set) => ({
    insights: [],
    message: '',
    error: false,
    loading: false,
    errorMessages: '',
    fetchInsights: async (data) => {
      set(() => ({ loading: true }));
      const response = await Api.Insights.getInsights(data);
      set(() => ({ insights: response, loading: false }));
    },
  })) as any
);

export default useInsightsStore;
