import { useState, useMemo } from 'react';
import Icon from '../../assets/icons/SvgComponent';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmStatus from '../../components/Modals/UserModal/confirmationStatus';
import Alert from '../../components/Alert';
import { useThemeStore } from '../../store/useThemeStore';
import TanStackTable from '../../components/TanStackTable';
import ImageIcon from '../../components/ImageIcon';
import EditUserModal from '../../components/Modals/UserModal/editUserModal';
import CustomModal from '../../components/CustomModal';
import { getRoleName } from '../../utils';

interface TeamsProps {
  selectedClient?: any;
  updateUserStatus?: any;
  isLoading?: any;
}

const Teams: React.FC<TeamsProps> = ({
  selectedClient,
  updateUserStatus,
  isLoading,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRow, setSelectedRow] = useState([]);
  const [clientDetails, setClientDetails] = useState(true);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState({});

  const type = (selectedUser) => {
    if (selectedUser === undefined) {
      return '';
    }
    if (selectedUser?.gurulytics_role === 'own' || 'stf') {
      return 'users';
    } else {
      return 'omg';
    }
  };

  const handleToggleStatus = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    updateUserStatus({ ids: id, status: newStatus });
    toast.success(
      <Alert
        icon=""
        title={`CLIENT USER STATUS`}
        message={`Client User's status been changed successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setConfirmationStatus(false);
    setConfirmStatus({});
  };

  let formattedUsers = useMemo(() => {
    if (
      !selectedClient ||
      !selectedClient?.users ||
      !Array.isArray(selectedClient?.users)
    ) {
      return [];
    }
    return selectedClient?.users
      ?.filter((item: any) =>
        ['am', 'seocon', 'ppccon', 'soccon'].includes(item.role)
      )
      ?.map((user: any) => ({
        nameAndEmail: {
          data: {
            name: user?.last_name
              ? `${user.first_name} ${user.last_name}`
              : user?.first_name || '',
          },
          name:
            `${user?.first_name} ${user.last_name ? user.last_name : ''}` || '',
          email: user?.email || '',
        },
        role: user?.role || '',
        isEnabled: user,
      }));
  }, [selectedClient]);

  const columns = [
    {
      id: 'Name and Email',
      header: () => (
        <div className="w-full flex flex-start pl-3">Name and Email</div>
      ),
      accessorKey: 'nameAndEmail',
      cell: (props: any) => {
        return (
          <div className="text-sm  flex gap-2 items-center w-[200px] sm:w-[40vw]  ml-2">
            <div>
              <ImageIcon
                data={props.getValue()?.data}
                size={8}
                textSize={'xs'}
              />
            </div>
            <div>
              <div className="font-semibold text-left w-[200px] sm:w-[40vw] truncate">
                {props.getValue()?.name}
              </div>
              <div className="font-base text-left w-[200px] sm:w-[40vw] truncate">
                {props.getValue()?.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      id: 'Role',
      header: 'Role',
      accessorKey: 'role',
      cell: (props: any) => (
        <div className="flex items-center gap-2 justify-center w-[300px] sm:w-full">
          <span className="text-xs rounded-full border max-w-fit bg-[#D9D6FE] px-2 py-1 uppercase dark:bg-[#fff] dark:text-[#000] font-inter">
            {getRoleName(props.getValue())}
          </span>
        </div>
      ),
    },
    {
      id: 'Status',
      header: () => (
        <div className="w-[100px] sm:w-[4vw] flex justify-center">Status</div>
      ),
      accessorKey: 'isEnabled',
      cell: (props: any) => (
        <div className="flex items-center gap-2 justify-center w-[100px] sm:w-[4vw]">
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              defaultChecked={props.getValue()?.status === 'active'}
              value={props.getValue()?.status}
              onClick={() => {
                setConfirmStatus(props.getValue());
                setConfirmationStatus(true);
              }}
            />
            <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
          </label>
        </div>
      ),
    },
    {
      id: 'Action',
      header: () => (
        <div className="w-[100px] sm:w-[3vw] flex justify-center">Action</div>
      ),
      accessorKey: 'isEnabled',
      cell: (props: any) => (
        <div className="flex gap-4 w-[100px] sm:w-[3vw] justify-center cursor-pointer">
          <div
            onClick={() => {
              setSelectedUser(props.getValue());
              setOpenEditModal(true);
            }}
          >
            <Icon
              name="UserEditIcon"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="w-full bg-white p-[20px]  mb-[32px] dark:bg-gray-800 rounded-lg">
      <div className="pt-2">
        <TanStackTable
          data={formattedUsers}
          columns={columns}
          totalPages={1}
          loading={isLoading}
          page={1}
          setPage={1}
          tableHeight="h-[60dvh]"
          setSelectedRow={setSelectedRow}
        />
      </div>
      <CustomModal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditUserModal
          user={selectedUser}
          type="omg"
          clientDetails={clientDetails}
          onClose={() => setOpenEditModal(false)}
        />
      </CustomModal>
      <CustomModal
        open={confirmationStatus}
        onClose={() => setConfirmationStatus(false)}
      >
        <ConfirmStatus
          user={confirmStatus}
          onClose={() => setConfirmationStatus(false)}
          action={handleToggleStatus}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};
export default Teams;
