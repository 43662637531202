import React, { useState, useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import usePerformanceStore from '../../store/usePerformanceStore';
import Icon from '../../assets/icons/SvgComponent';
import { Badge } from '@tremor/react';

interface IProps {
  onClose?: () => void;
  isOpen?: boolean;
  handleFilterChange?: (selectedOptions: string[], type?: string) => void;
  cycle?: any;
  clients?: any;
  integration?: any;
  formattedDataMemo?: any[];
  date?: any;
  searchText?: string;
  setSearchText?: React.Dispatch<React.SetStateAction<string>>;
  handleSearch?: () => void;
  performanceFilterStorage: any;
}

const roleOptions = [
  { value: 'clients', label: 'Clients' },
  { value: 'users', label: 'Users' },
];

const MultiFilterPerformanceModal: React.FC<IProps> = ({
  onClose,
  isOpen,
  handleFilterChange,
  handleSearch,
  performanceFilterStorage,
  formattedDataMemo,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const {
    clientsOptions,
    fetchClients,
    clearOptions,
    usersOptions,
    fetchUsers,
  } = usePerformanceStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDropDown, setSelectedDropDown] = useState<{
    label: string;
    value: string;
  }>({ label: 'Clients', value: 'clients' });
  const isClient = selectedDropDown?.value === 'clients';
  const dropDownOptions = isClient ? clientsOptions : usersOptions;
  const [showDropdown, setShowDropdown] = useState(false);

  const [role, setRole] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Clients',
    value: 'clients',
  });

  const debouncedFetch = useCallback(
    debounce((term) => {
      isClient ? fetchClients(term) : fetchUsers(term);
    }, 500),
    [fetchClients, fetchUsers, isClient]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const performanceFilterStorage = localStorage.getItem(
      'links-filter-storage'
    );
    if (performanceFilterStorage) {
      const parsedData = JSON.parse(performanceFilterStorage);
      setSelectedOptions(parsedData.options);
      setRole({
        label: parsedData.type === 'clients' ? 'Clients' : 'Users',
        value: parsedData.type,
      });
    } else {
      setSelectedOptions([]);
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      clearOptions();
      debouncedFetch(searchTerm);
    }
    if (searchTerm.length === 0) {
      clearOptions();
    }
  }, [searchTerm, debouncedFetch, clearOptions]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setSearchTerm(term);
    setShowDropdown(true);
  };

  const handleOptionClick = (option) => {
    const isOptionSelected = selectedOptions.some(
      (selectedOption) => selectedOption.id === option.id
    );
    if (!isOptionSelected) {
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = [...prevSelectedOptions, option];
        handleFilterClick(newSelectedOptions);
        return newSelectedOptions;
      });
    }
    setSearchTerm(option.name || `${option.first_name} ${option.last_name}`);
    setShowDropdown(false);
  };

  const handleFilterClick = (options = selectedOptions) => {
    const storageKey = 'links-filter-storage';
    if (
      localStorage.getItem(storageKey) &&
      selectedDropDown.label !== 'Clients'
    ) {
      localStorage.removeItem(storageKey);
    }
    const dataToStore = {
      type: selectedDropDown.value,
      options: options,
    };
    localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    window.dispatchEvent(new Event('storage'));
    setSearchTerm('');
    onClose();
  };

  const handleFilterClickWithOptions = () => {
    handleFilterClick(selectedOptions);
  };

  const handleSelectedRole = (option) => {
    setSelectedDropDown(option);
    setRole(option);
    setSearchTerm('');
  };

  const handleClearFilter = () => {
    setSelectedOptions([]);
    setSearchTerm('');
    localStorage.removeItem('links-filter-storage');
    window.dispatchEvent(new Event('storage'));
    clearOptions();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      inputRef.current &&
      !inputRef.current.contains(event.target as Node)
    ) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFilterClick();
      setSearchTerm('');
    }
  };

  const handleOptionRemoval = (index) => {
    const newSelectedOptions = [...selectedOptions];
    newSelectedOptions.splice(index, 1);
    setSelectedOptions(newSelectedOptions);
    const storageKey = 'links-filter-storage';
    const dataToStore = {
      type: selectedDropDown.value,
      options: newSelectedOptions,
    };
    localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <div className="relative w-full">
      <div className="flex gap-0">
        <div
          className={`w-fit h-fit border flex gap-1 py-[0.63rem] pl-[10px] text-xs rounded-tl-lg rounded-bl-lg dark:border-gray-600`}
        >
          {roleOptions.map((role, index) => (
            <div
              key={index}
              onClick={() => {
                handleSelectedRole(role);
                handleClearFilter();
              }}
              className={`${
                selectedDropDown.label === role.label &&
                'bg-[#D0EDFF] text-[#001C44] font-[600] px-1 py-[0.5] rounded'
              }`}
            >
              {role.label}
            </div>
          ))}
          <div
            className="border-l px-1 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <Icon name="ChevronDown" size={16} />
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex items-center w-full">
            {selectedOptions.length > 0 && (
              <div className="border-y py-[0.5rem] px-1 w-fit">
                <div className="flex items-center gap-1 h-full">
                  {/* {selectedOptions?.slice(0, 1).map((option, index) => (
                    <div
                      key={index}
                      className="p-[0.1rem] border rounded flex items-center justify-between text-xs"
                    >
                      <span className="max-w-[20px] truncate">
                        {option.name ||
                          `${option.first_name} ${option.last_name}`}
                      </span>
                      <button
                        className="ml-2"
                        onClick={() => handleOptionRemoval(index)}
                      >
                        <svg
                          className="w-4 h-4 text-blue-800"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M6 18L18 6M6 6l12 12"></path>
                        </svg>
                      </button>
                    </div>
                  ))} */}
                  {performanceFilterStorage?.options?.length > 0 && (
                    <Badge
                      size="xs"
                      color="stone"
                      onClick={() => setShowDropdown(true)}
                      className="cursor-pointer"
                    >
                      <div>
                        {`+${performanceFilterStorage?.options?.length}`}
                      </div>
                    </Badge>
                  )}
                </div>
              </div>
            )}
            <div className="relative">
              <input
                ref={inputRef}
                type="search"
                className={`block p-[0.5rem] ${performanceFilterStorage?.options?.length > 0 ? 'w-full sm:w-[214px]' : 'w-full sm:w-[250px]'}  z-50 text-sm text-gray-900 rounded-e-lg border-s-gray-50 border-s-2 border-y border-r border-gray-300 dark:bg-gray-700 dark:border-s-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                placeholder={
                  selectedDropDown.value === 'clients'
                    ? 'Search by client'
                    : 'Search by user'
                }
                disabled={false}
                value={searchTerm}
                onChange={handleInputChange}
                onFocus={() => setShowDropdown(true)}
                onKeyDown={handleKeyDown}
              />
              <button
                className={`${
                  searchTerm?.length > 0 ? 'hidden' : 'absolute'
                } top-0 end-0 p-2.5 text-sm font-medium h-full text-white rounded-e-lg`}
                onClick={handleFilterClickWithOptions}
              >
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="#334969"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
          {(searchTerm?.length > 0 || showDropdown) && (
            <div
              ref={dropdownRef}
              className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1"
            >
              <div className="w-full sm:w-[250px] max-h-[170px] overflow-y-scroll overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                {dropDownOptions?.data?.map((option, index) => (
                  <div
                    key={index}
                    className="px-3 py-2 hover:bg-gray-100 text-[#000] cursor-pointer text-xs"
                    onClick={() => handleOptionClick(option)}
                  >
                    {option?.name ||
                      option?.first_name + ' ' + option?.last_name}
                  </div>
                ))}
              </div>
              {selectedOptions.length > 0 && !dropDownOptions.loading ? (
                <div className="my-2 px-2 border-t">
                  <div className="flex flex-wrap gap-2 mt-2">
                    {selectedOptions.map((option, index) => (
                      <div
                        key={index}
                        className="px-2 py-1 border rounded-md flex items-center justify-between text-xs"
                      >
                        <span className="max-w-[71px] truncate">
                          {option.name ||
                            `${option.first_name} ${option.last_name}`}
                        </span>
                        <button
                          className="ml-2"
                          onClick={() => handleOptionRemoval(index)}
                        >
                          <svg
                            className="w-4 h-4 text-blue-800"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M6 18L18 6M6 6l12 12"></path>
                          </svg>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <>
                  {dropDownOptions.loading ? (
                    <div className="w-full h-full flex items-center justify-center py-[0.9rem]">
                      <Icon name="Loading" size={20} />
                    </div>
                  ) : (
                    <>
                      {dropDownOptions?.data?.length === 0 ? (
                        <div className="text-center text-xs text-gray-400 py-4 border-t">
                          No {selectedDropDown.value} found.
                        </div>
                      ) : (
                        <div className="text-center text-xs text-gray-400 py-8">
                          No selected {selectedDropDown.value}
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MultiFilterPerformanceModal;
