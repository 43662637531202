// WeeklyReport.tsx
import React, { useEffect, useState } from 'react';
// import { useThemeStore } from '../../store/useThemeStore';
// import { BarChart, Card } from '@tremor/react';
import {
  cycleOptions,
  typeOptions,
  typeObj,
  cycleObj,
} from '../../components/ReportTanStackTable/staticData';
import moment from 'moment';
import PieChart from '../../components/Insights/PieChart';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useReportStore from '../../store/useReportStore';
import SearchDropdown from '../../components/SearchDropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import StatusCounter from '../../components/Report/StatusCounter';
import useInsightsStore from '../../store/useInsightsStore';

type ParameterValue = {
  label: string;
  id: string | boolean;
};
type ParameterObject = {
  [key: string]: ParameterValue;
};

const Insights: React.FC = () => {
  const {
    reportHSInsights,
    fetchReportSummary,
    reportHSInsightsAm,
    reportHSInsightsCons,
    getAmHsInsightsRole,
    getConsHsInsightsRole,
    summary,
  } = useReportStore((state) => state);
  const { cycle, type, reportDate, all, select } = useReportStore(
    (state) => state.filters
  );
  const lastMonth = moment().subtract(1, 'month').format('YYYY-MM');
  const { fetchInsights, insights } = useInsightsStore((state) => state);

  const handleFetchInsights = () => {
 
    const payload = {
      report_date: lastMonth,
    };
    fetchInsights(payload);
  };

  useEffect(() => {
    handleFetchInsights();
  }, []);


  const [loading, setLoading] = useState<boolean>(false);
  const [mount, setMount] = useState(false);

  const [filter, setFilter] = useState({
    cycle: cycleOptions[0],
    type: typeOptions[0],
    reportDate: reportDate || lastMonth,
  });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInsights = async (data: any) => {
      setLoading(true);
      getAmHsInsightsRole(data);
      getConsHsInsightsRole(data);
    };

    const data = {
      date: filter.reportDate,
      type: filter.type?.id || 'all',
      cycle: filter.cycle?.id,
    };
    const filters = {
      date: filter.reportDate,
      type: filter.type.id,
      cycle: filter.cycle.id,
      all_users: true,
      missing_field: select.id,
      report_basis: 'monthly',
    };
    if (mount) {
      fetchReportSummary(filters);
      fetchInsights(data);
      setMount(false);
    }
  }, [filter.reportDate, filter.type, filter.cycle]);

  useEffect(() => {
    const filters = setFiltersFromURL();
    setMount(true);
    setFilter(filters);
  }, [location]);

  const searchParams = new URLSearchParams(location.search);

  const getURLParameter = (
    parameter: string,
    defaultValue: any,
    parameterObj: ParameterObject | null = null
  ): any => {
    const paramValue = searchParams.get(parameter);
    if (parameterObj && paramValue) {
      return parameterObj[paramValue];
    }
    return defaultValue;
  };
  const dateRegex = /^(0[1-9]|1[0-2])-(20)\d{2}$/;
  const isDateValid = (date: any) => dateRegex.test(date);

  const setFiltersFromURL = () => {
    const urlType = getURLParameter('type', type, typeObj);
    const urlCycle = getURLParameter('cycle', cycle, cycleObj);
    const urlDate = isDateValid(searchParams.get('date'))
      ? searchParams.get('date')
      : reportDate;

    return {
      cycle: urlCycle,
      type: urlType,
      reportDate: urlDate,
    };
  };

  const amChartData = reportHSInsightsAm?.data?.dates?.map(
    (date: any, index: number) => {
      const greenData = reportHSInsightsAm?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'green'
      )?.data[index];
      const amberData = reportHSInsightsAm?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'amber'
      )?.data[index];
      const redData = reportHSInsightsAm?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'red'
      )?.data[index];

      return {
        Month: date,
        Green: greenData || 0,
        Amber: amberData || 0,
        Red: redData || 0,
      };
    }
  );

  const consChartData = reportHSInsightsCons?.data?.dates?.map(
    (date: any, index: number) => {
      const greenData = reportHSInsightsCons?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'green'
      )?.data[index];
      const amberData = reportHSInsightsCons?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'amber'
      )?.data[index];
      const redData = reportHSInsightsCons?.data?.datasets?.find(
        (item: any) => item.datasetLabel === 'red'
      )?.data[index];

      return {
        Month: date,
        Green: greenData || 0,
        Amber: amberData || 0,
        Red: redData || 0,
      };
    }
  );

  const handleFiltersChange = (type: any, val: any) => {
    let value = val?.id;
    if (type === 'date') value = moment(val).format('MM-YYYY');
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(type, value);
    const updatedSearch = searchParams?.toString();
    location.search = updatedSearch;
    navigate(location);
  };

  const getCurrentMonthYear = () => moment().format('MMMM yyyy');

  return (
    <div className="bg-[#D2EEFF] mt-20 px-2 sm:mt-0 sm:py-3 sm:px-[32px] overflow-hidden dark:bg-[#191919] min-h-[93dvh] font-inter ">
      <div className="block  md:flex  justify-between items-center bg-white px-4 py-2 dark:bg-dark-tremor-background rounded-lg mb-4">
        <div className="flex flex-wrap items-center gap-2 sm:gap-4 dark:bg-dark-tremor-background sticky top-0 z-10 ">
          <div className="z-20 font-inter">
            <DatePicker
              selected={moment(filter.reportDate, 'MM-yyyy').toDate()}
              onChange={(date) => {
                if (date !== null) {
                  handleFiltersChange('date', date);
                }
              }}
              dateFormat="MMM yyyy"
              maxDate={new Date(getCurrentMonthYear())}
              showMonthYearPicker
              className="react-datepicker__month-container text-center h-[38px] rounded-lg border border-[#D1D5DB] cursor-pointer myDatePicker dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
            />
          </div>
          <SearchDropdown
            customStyle={{ width: 150 }}
            labelKey="label"
            valueKey="id"
            onSelect={(type) => handleFiltersChange('type', type)}
            defaultValue={filter.type.label}
            placeholder={type.label}
            options={typeOptions || []}
          />
          <SearchDropdown
            customStyle={{ width: 'fit-content', minWidth: 100 }}
            labelKey="label"
            valueKey="id"
            onSelect={(value) => handleFiltersChange('cycle', value)}
            defaultValue={filter.cycle.label}
            placeholder={cycle.label}
            options={cycleOptions || []}
          />
        </div>
      </div>
      <div className="block md:flex  justify-between  bg-white px-4 py-4 dark:bg-dark-tremor-background rounded-lg mb-4">
        <StatusCounter loading={summary?.loading} />
      </div>
      <div className="flex flex-col gap-4  md:flex-row  justify-between items-center bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-4">
        <PieChart
          data={amChartData}
          title="Account Manager"
          reportHSInsights={reportHSInsights}
          loading={reportHSInsightsAm?.loading}
        />
        <PieChart
          data={consChartData}
          title="Consultant"
          reportHSInsights={reportHSInsights}
          loading={reportHSInsightsCons?.loading}
        />
      </div>

      {/* <div className="block md:flex  justify-between  bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-[23px]">
        <SummaryEngagements data={summaryOptions} title="Summary" />
        <SummaryEngagements data={engagements} title="Engagements" />
      </div>
      <div className="block bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-[23px]">
        <OpenTrend />
      </div> */}
      {/* <div className="block md:flex  justify-between gap-2  bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-[23px]">
        <Reads title="Total Opens/Reads" />
        <Reads title="Unique Opens/Reads" />
        <Reads title="Did Not Open/Reads" />
        <Reads title="Avg. Recipients Per Open/Read" />
      </div>
      <div className="block md:flex  justify-between gap-2  bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-[23px]">
        <InsightsTable />
      </div> */}
      {/* <div className="block md:flex  justify-between  bg-white p-4 dark:bg-dark-tremor-background rounded-lg mb-[23px]">
        <CountryEmailClient
          data={country}
          headerName="Country"
          headerValue="Views"
        />
        <CountryEmailClient
          data={source}
          headerName="Source"
          headerValue="Views"
        />
      </div> */}
    </div>
  );
};

export default Insights;
