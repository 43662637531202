import { constructURLv2, requests } from './ApiService';
import { toQueryString } from '../utils/common';

export const Report = {
  getReports: (filters) =>
    requests.get(
      `/api/v1/monthly-reports${toQueryString({
        search: filters.search,
        report_cycle: filters.cycle,
        page: filters.page,
        limit: filters.limit,
        report_date: filters.date,
        report_type: filters.type,
        client_status: filters?.client_status,
        status: filters?.status,
        all: filters?.all,
        select: filters.select,
        sort_by: filters.sortBy,
        sort_order: filters.sortOrder,
        health_status: filters?.healthStatus,
        client_id: filters?.client_id,
      })}`
    ),
  getReportsv2: (filters) =>
    requests.get(
      `/api/v1/reports${toQueryString({
        search: filters.search,
        report_cycle: filters.cycle,
        page: filters.page,
        limit: filters.limit,
        report_date: filters.date,
        report_type: filters.type,
        client_status: filters?.client_status,
        status: filters?.status,
        all: filters?.all,
        select: filters.select,
        sort_by: filters.sortBy,
        sort_order: filters.sortOrder,
        health_status: filters?.healthStatus,
        client_id: filters?.client_id,
        report_period: filters?.period,
        by_error: filters?.by_error,
      })}`
    ),
  getErrors: (filters) =>
    requests.get(
      `/api/v1/reports/filters/by_error${toQueryString({
        refreshCache: filters.refreshCache,
        report_basis: filters.report_basis,
      })}`
    ),
  getHSInsights: (filters) =>
    requests.get(
      `/api/v1/reports/summary/health_status${toQueryString({
        date: filters.date,
        cycle: filters.cycle,
        report_type: filters.type,
        role: filters.role,
      })}`
    ),
  getReport: (filters) =>
    requests.get(
      `/api/v1/reports${toQueryString({
        page: filters.page,
        limit: filters.limit,
        report_date: filters.date,
        report_type: filters.type,
        client_id: filters.client_id,
        status: filters.status,
        sort_by: filters.sort_by,
        sort_order: filters.sort_order,
        by_error: filters.by_error,
      })}`
    ),
  getReportId: (filters) => requests.get(`/api/v1/report/${filters.client_id}`),
  generate: (data) => requests.patch(`/api/v1/weekly-reports/regenerate`, data),
  aiGenerate: (data) =>
    requests.get(`/api/v1/report/generate_content/${data.id}`),
  update: (id, data) => requests.patch(`/api/v1/report/${id}`, data),
  bulkUpdate: (data) => requests.patch(`/api/v1/reports`, data),
  create: (data) => requests.post('/api/v1/report', data),
  send: (data) => requests.post('/api/v1/reports/send', data),
  uploadReports: (data) =>
    requests.post('/api/v1/reports/upload', data, 'multipart/form-data'),
  getUploadStatus: () => requests.get('/api/v1/reports/uploads_status'),
  summary: (filters) =>
    requests.get(
      `/api/v1/reports/summary${toQueryString({
        summary_date: filters.date,
        cycle: filters.cycle,
        report_type: filters.type,
        report_basis: filters.report_basis,
        all_users: filters.all_users,
        missing_field: filters.missing_field,
      })}`
    ),
  healthStatusHistory: (filters) =>
    requests.get(
      `/api/v1/report/health_status/${filters.client_id}?page=1&limit=10&report_type=${filters.report_type}`
    ),
  comments: (filters) =>
    requests.get(
      `/api/v1/report/comments/${filters.client_id}?page=1&limit=10&report_type=${filters.report_type}&date=${filters.date}`
    ),
};
