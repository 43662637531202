import React, { useState, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useKpiStore from '../../store/useKpiStore';
import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';

const KPIs = () => {
  const date = new Date();
  const { fetchKpis, kpis, selectedStaff, setStaff, autoStaff, loading } = useKpiStore((state) => state);
  const [selectedServiceLines, setSelectedServiceLines] = useState([]);
  const [selectedChangeTypes, setSelectedChangeTypes] = useState([]);
  const [monthYearPairs, setMonthYearPairs] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    currentMonth: moment(date).format('MMMM'),
    previousMonth: moment(date).subtract(1, 'month').format('MMMM'),
    reportFrom: moment(date).subtract(1, 'month').startOf('day'),
    reportTo: moment(date).endOf('day'),
    role: [],
    service_line:[]
  });

  const [sortBy, setSortBy] = useState('full_name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [filters, setFilters] = useState({
    user: '',
    tags: [],
    year: '',
  });
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);

  const handleSort = (column) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(column);
      setSortOrder('asc');
    }
  };

  const handleReportDateChange = (date, type) => {
    if (date) {
      if(type === 'from'){
        setPayload(prevPayload => ({
          ...prevPayload,
          reportFrom: moment(date).subtract(1, 'month').startOf('day'),
        }));
      }else{
        setPayload(prevPayload => ({
          ...prevPayload,
          reportTo: moment(date).endOf('day'),
        }));
      }
    }
  };

  const handleTagInput = (e) => {
    const input = e.target as HTMLInputElement;
    if (input.value.trim()) {
      setSuggestions(data.filter(item =>
        item.full_name.toLowerCase().includes(input.value.toLowerCase())
      ));
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const selectTag = (name) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: [...prevFilters.tags, name],
    }));
    setShowSuggestions(false);
  };

  const removeTag = (tag) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: prevFilters.tags.filter(t => t !== tag),
    }));
  };

  const handleRoleCheckboxChange = (event) => {
    const value = event.target.value;
    setPayload(prevPayload => ({
      ...prevPayload,
      role: event.target.checked
        ? [...prevPayload.role, value]  
        : prevPayload.role.filter(item => item !== value)  
    }));
  };

  const filterByServiceLineAndChangeType = (data, selectedServiceLines, selectedChangeTypes) => {
    if (data.length) {
      return data.filter(item => {
        const serviceLineMatches = selectedServiceLines.length === 0 || 
          Object.values(item.months).some((monthData: Array<{ serviceLine: string }>) =>
            monthData.some(entry => selectedServiceLines.includes(entry.serviceLine))
          );
        const changeTypeMatches = selectedChangeTypes.length === 0 || 
          Object.values(item.months).some((monthData: Array<{ changeType: string }>) =>
            monthData.some(entry => selectedChangeTypes.includes(entry.changeType))
          );
        return serviceLineMatches && changeTypeMatches;
      });
    }
    return [];
  };


  function calculateReductions(value) {
    var total = 0
    value.map(v => {
      switch(v.changeType){
        case 'cancel':
          total += parseInt(v.changeDetails.cancelBudget)
          break;
        case 'apply':
          total += parseInt(v.changeDetails.applyCreditAmount)
          break;
        case 'stop':
          total += parseInt(v.changeDetails.stopWorkBudget)
          break;
        case 'reduce':
          total += (parseInt(v.changeDetails.reduceBillingBudget) - parseInt(v.changeDetails.reduceBillingNewBudget))
          break;
        case 'refund':
          total += parseInt(v.changeDetails.refundAmount)
          break;
        default:
          total += 0
      }
    })
    return total;
}

function calculateUtilization(value, reduction, target) {
  const adjustedTarget = target - calculateReductions(reduction);
  const utilizationPercentage = (value / adjustedTarget) * 100;
  if(utilizationPercentage >= 96){
    return <span className="text-green-500">{utilizationPercentage.toFixed(2)}%</span>
  }else{
    return <span className="text-red-500">{utilizationPercentage.toFixed(2)}%</span>
  }
}

  const getMonthYearPairs = (startDate, endDate) => {
    const months = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.toLocaleString('default', { month: 'long' });
      months.push({ month, year });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return months;
  };

  const handleServiceCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedServiceLines(prev => event.target.checked ? [...prev, value] : prev.filter(item => item !== value));
  };
  
  const handleChangeTypeCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedChangeTypes(prev => event.target.checked ? [...prev, value] : prev.filter(item => item !== value));
  };

  const filteredData = useMemo(() => {
    const basicFilteredData = data.filter((item) => {
      const fullNameMatch = item.full_name.toLowerCase().includes(filters.user.toLowerCase());
      const tagsMatch = filters.tags.length
        ? filters.tags.some(tag => item.full_name.toLowerCase().includes(tag.toLowerCase()))
        : true;
      const yearMatch = filters.year ? item.year === filters.year : true;
      return fullNameMatch && tagsMatch && yearMatch;
    });
    
    return filterByServiceLineAndChangeType(basicFilteredData, selectedServiceLines, selectedChangeTypes);
  }, [data, filters, selectedServiceLines, selectedChangeTypes]);

  const sortedData = useMemo(() => {
    return [...filteredData].sort((a, b) => {
      if (a[sortBy] < b[sortBy]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortBy] > b[sortBy]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortBy, sortOrder]);
  
  useEffect(() => {
    setLoading(true)
    const startDate = new Date(payload.reportFrom.toISOString());
    const endDate = new Date(payload.reportTo.toISOString());
    const pairs = getMonthYearPairs(startDate, endDate);
    setMonthYearPairs(pairs);

    fetchKpis(payload)
    .then(data => {
        setData(data.data);
        setLoading(false)
    });
}, [payload]);

  return (
    <div className="p-4 kpis">
      <div className="mb-4 relative kpi-filters">
        <label><span>Filter Name</span>
          <input
            type="text"
            placeholder="Search and select a user"
            onChange={handleTagInput}
            className="p-2 border border-gray-300 rounded w-[250px]"
          />
        </label>
        {showSuggestions && (
          <div className="kpi-suggestions absolute bg-white border border-gray-300 mt-1 max-h-60 overflow-auto">
            {suggestions.map((item) => (
              <div
                key={item._id.$oid}
                className="p-2 cursor-pointer hover:bg-gray-100"
                onClick={() => selectTag(item.full_name)}
              >
                {item.full_name}
              </div>
            ))}
          </div>
        )}
        <label><span>Date From</span>
          <DatePicker
            selected={payload.reportFrom.toDate()}
            onChange={(date) => {
              if (date !== null) {
                handleReportDateChange(date, 'from');
              }
            }}
            className="p-2 ml-2 border border-gray-300 rounded" 
          />
        </label>
        <label><span>Date To</span>
          <DatePicker
            selected={payload.reportTo.toDate()}
            onChange={(date) => {
              if (date !== null) {
                handleReportDateChange(date, 'to');
              }
            }}
            className="p-2 ml-2 border border-gray-300 rounded" 
          />
        </label>
      </div>
      <div className="mb-4 relative kpi-filters checks">
          <h6>Service Lines</h6>
          <label>
            <input type="checkbox" value="seo" onChange={e => handleServiceCheckboxChange(e)} />
            <span>SEO GOOGLE</span>
          </label>
          <label>
            <input type="checkbox" value="seobing" onChange={e => handleServiceCheckboxChange(e)} />
            <span>SEO BING</span>
          </label>
          <label>
            <input type="checkbox" value="ppc" onChange={e => handleServiceCheckboxChange(e)} />
            <span>PPC GOOGLE</span>
          </label>
          <label>
            <input type="checkbox" value="ppcbing" onChange={e => handleServiceCheckboxChange(e)} />
            <span>PPC BING</span>
          </label>
          <label>
            <input type="checkbox" value="meta" onChange={e => handleServiceCheckboxChange(e)} />
            <span>META</span>
          </label>
          <label>
            <input type="checkbox" value="pinterest" onChange={e => handleServiceCheckboxChange(e)} />
            <span>PINTEREST</span>
          </label>
          <label>
            <input type="checkbox" value="tiktok" onChange={e => handleServiceCheckboxChange(e)} />
            <span>TIKTOK</span>
          </label>
          <label>
            <input type="checkbox" value="linkedin" onChange={e => handleServiceCheckboxChange(e)} />
            <span>LINKEDIN</span>
          </label>
          <label>
            <input type="checkbox" value="creative" onChange={e => handleServiceCheckboxChange(e)} />
            <span>CREATIVE</span>
          </label>
          <label>
            <input type="checkbox" value="programmatic" onChange={e => handleServiceCheckboxChange(e)} />
            <span>PROGRAMMATIC</span>
          </label>
          <label>
            <input type="checkbox" value="tactical" onChange={e => handleServiceCheckboxChange(e)} />
            <span>TACTICAL</span>
          </label>
      </div>
      <div className="mb-4 relative kpi-filters checks">
        <h6>Change Types</h6>
        <label>
          <input type="checkbox" value="apply_credit" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>APPLY CREDIT</span>
        </label>
        <label>
          <input type="checkbox" value="refund" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>REFUND</span>
        </label>
        <label>
          <input type="checkbox" value="cancel" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>CANCEL</span>
        </label>
        <label>
          <input type="checkbox" value="cancel" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>PAUSE</span>
        </label>
        <label>
          <input type="checkbox" value="cancel" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>REDUCE BILLING</span>
        </label>
        <label>
          <input type="checkbox" value="cancel" onChange={e => handleChangeTypeCheckboxChange(e)} />
          <span>DELAY BILLING</span>
        </label>
      </div>
      <div className={filters.tags.length > 0 ? 'hidden mb-4 relative kpi-filters checks' : 'mb-4 relative kpi-filters checks'}>
          <h6>Roles</h6>
          <label>
            <input type="checkbox" value="gad" onChange={e => handleRoleCheckboxChange(e)} />
            <span>GAD</span>
          </label>
          <label>
            <input type="checkbox" value="am" onChange={e => handleRoleCheckboxChange(e)} />
            <span>AM</span>
          </label>
          <label>
            <input type="checkbox" value="seocon" onChange={e => handleRoleCheckboxChange(e)} />
            <span>SEO</span>
          </label>
          <label>
            <input type="checkbox" value="ppccon" onChange={e => handleRoleCheckboxChange(e)} />
            <span>PPC</span>
          </label>
          <label>
            <input type="checkbox" value="soccon" onChange={e => handleRoleCheckboxChange(e)} />
            <span>SOCIAL</span>
          </label>
          <label>
            <input type="checkbox" value="exec" onChange={e => handleRoleCheckboxChange(e)} />
            <span>EXEC</span>
          </label>
      </div>
      <div className="mb-4">
        {filters.tags.length > 0 && (
          <div className="flex flex-wrap gap-2 mb-2">
            {filters.tags.map(tag => (
              <span key={tag} className="kpi-tags bg-blue-200 text-blue-800 px-2 py-1 rounded flex items-center">
                {tag}
                <button
                  onClick={() => removeTag(tag)}
                  className="ml-2"
                >
                  ×
                </button>
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="mb-4" style={{background:'white'}}>
      {isLoading ? 
        <div className="right-16 top-5">
          <Icon name="loading" />
        </div>
       :
      <table className="min-w-full bg-white border border-gray-300 kpi-table">
        <thead>
          <tr className="bg-gray-100">
            <th className="p-2 cursor-pointer" onClick={() => handleSort('full_name')}>
              User Name {sortBy === 'full_name' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('rum')}>
              Rum {sortBy === 'rum' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('target')}>
              Rum Target {sortBy === 'target' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('reductions')}>
              Reductions {sortBy === 'reductions' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('utilisation')}>
              Rum Utilisation {sortBy === 'utilisation' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('previousMonth')}>
              Previous Month {sortBy === 'previousMonth' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
              Year {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
              Role {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
            </th>
            {monthYearPairs.map((pair) => (
              <th className="p-2 cursor-pointer" onClick={() => handleSort('year')}>
                {pair.month} {sortBy === 'year' ? (sortOrder === 'asc' ? '🔼' : '🔽') : ''}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item) => (
            <tr key={item._id}>
              <td className="p-2 border border-gray-300">{item.full_name}</td>
              <td className="p-2 border border-gray-300">
              {item.months && item.months[`${payload.currentMonth}Rum`] && item.months[`${payload.currentMonth}Rum`][0] && item.months[`${payload.currentMonth}Rum`][0].rum
                ? item.months[`${payload.currentMonth}Rum`][0].rum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : '0'
              }
              </td>
              <td className="p-2 border border-gray-300">
              {item.months && item.months[`${payload.currentMonth}Rum`] && item.months[`${payload.currentMonth}Rum`][0] && item.months[`${payload.currentMonth}Rum`][0].rum_target
                ? item.months[`${payload.currentMonth}Rum`][0].rum_target.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 'Not Set'
              }
              </td>
              <td className="p-2 border border-gray-300">
                {
                item.months && item.months[`${payload.currentMonth}Rum`] && item.months[`${payload.currentMonth}Rum`][0] && item.months[`${payload.currentMonth}Rum`][0].rum_target && item.months[`${payload.currentMonth}Rum`][0].rum
                ? <span className="text-red-500">{calculateReductions(item.months[`${payload.currentMonth}`]).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                : <span className="text-green-500">0</span>
                }
              </td>
              <td className="p-2 border border-gray-300">
                {
                item.months && item.months[`${payload.currentMonth}Rum`] && item.months[`${payload.currentMonth}Rum`][0] && item.months[`${payload.currentMonth}Rum`][0].rum_target && item.months[`${payload.currentMonth}Rum`][0].rum
                ? calculateUtilization(
                    item.months[`${payload.currentMonth}Rum`][0].rum, 
                    item.months[`${payload.currentMonth}`], 
                    item.months[`${payload.currentMonth}Rum`][0].rum_target
                  )
                : <span className="text-green-500">100%</span>
                }
              </td>
              <td className="p-2 border border-gray-300">
                {
                item.months && item.months[`${payload.previousMonth}Rum`] && item.months[`${payload.previousMonth}Rum`][0] && item.months[`${payload.previousMonth}Rum`][0].rum_target && item.months[`${payload.previousMonth}Rum`][0].rum
                ? calculateUtilization(
                    item.months[`${payload.previousMonth}Rum`][0].rum, 
                    item.months[`${payload.previousMonth}`], 
                    item.months[`${payload.previousMonth}Rum`][0].rum_target
                  )
                : <span className="text-green-500">100%</span>
                }
              </td>
              <td className="p-2 border border-gray-300">{item.year}</td>
              <td className="p-2 border border-gray-300">
                {
                item.gurulytics_role === 'seocon' ? 'SEO Consultant' : 
                item.gurulytics_role === 'ppccon' ? 'PPC Consultant' : 
                item.gurulytics_role === 'soccon' ? 'SOCIAL Consultant' : 
                item.gurulytics_role === 'am' ? 'AM' : 
                item.gurulytics_role === 'gad' ? 'GAD' : 
                item.gurulytics_role === 'admin' ? 'ADMIN' : 
                item.gurulytics_role === 'exec' ? 'Executive' : 'N/A'
                }
              </td>
              {monthYearPairs.map((pair, index) => {
                const dynamicMonthKey = `${pair.month}Rum`;
                return (
                  <td key={index} className="p-2 border border-gray-300">
                    {item.months && item.months[dynamicMonthKey] && item.months[dynamicMonthKey][0] && item.months[dynamicMonthKey][0].rum
                      ? item.months[dynamicMonthKey][0].rum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : '0'
                    }
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      }
      </div>
    </div>
  );
};

export default KPIs;


