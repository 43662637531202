import React, { useState, useCallback, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import usePerformanceStore from '../../../store/usePerformanceStore';
import Icon from '../../../assets/icons/SvgComponent';

interface IProps {
  handleFetchLinkSearchItems: () => void;
}

const roleOptions = [
  { value: 'client', label: 'Client' },
  { value: 'user', label: 'User' },
  { value: 'link', label: 'Link' },
];

const LinksLookupSearch: React.FC<IProps> = ({
  handleFetchLinkSearchItems,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const { clientsOptions, fetchClients, clearOptions } = usePerformanceStore();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDropDown, setSelectedDropDown] = useState({
    label: 'Client',
    value: 'client',
  });
  const dropDownOptions = clientsOptions; // Only clients now
  const [showDropdown, setShowDropdown] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const debouncedFetch = useCallback(
    debounce((term) => {
      fetchClients(term); // Only fetching clients
    }, 500),
    [fetchClients]
  );

  useEffect(() => {
    const storedFilter = localStorage.getItem('links-filter-storage');
    if (storedFilter) {
      const parsedData = JSON.parse(storedFilter);
      setSelectedOptions(parsedData.options);
      setSearchTerm(parsedData.searchTerm || ''); // Set the search term if it exists
    } else {
      setSelectedOptions([]);
    }
  }, []);

  useEffect(() => {
    if (searchTerm.length >= 3) {
      clearOptions();
      debouncedFetch(searchTerm);
    }
    if (searchTerm.length === 0) {
      clearOptions();
    }
  }, [searchTerm, debouncedFetch, clearOptions]);

  useEffect(() => {
    if (
      selectedDropDown.value === 'user' ||
      selectedDropDown.value === 'link'
    ) {
      setShowDropdown(false);
    }
  }, [selectedDropDown]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    if (
      selectedDropDown.value !== 'user' &&
      selectedDropDown.value !== 'link'
    ) {
      setShowDropdown(true);
    }
  };

  const handleOptionClick = (option) => {
    const isOptionSelected = selectedOptions.some(
      (selectedOption) => selectedOption.id === option.id
    );
    if (!isOptionSelected) {
      setSelectedOptions((prevSelectedOptions) => {
        const newSelectedOptions = [...prevSelectedOptions, option];
        handleFilterClick(newSelectedOptions);
        return newSelectedOptions;
      });
    }

    // Set the selected client name in the search term
    setSearchTerm(option.name || `${option.first_name} ${option.last_name}`);

    // Immediately close the dropdown after an option is selected
    setShowDropdown(false);

    // Trigger any further actions like fetching or filtering items
    handleFetchLinkSearchItems();
  };

  const handleFilterClick = (options = selectedOptions) => {
    let dataToStore;

    if (selectedDropDown.value === 'client') {
      // Safely access hubspot.company_id if it exists
      const hubspotId = options?.[0]?.hubspot?.company_id || '';
      dataToStore = {
        type: selectedDropDown.value,
        options: options,
        searchTerm: searchTerm,
        hubspotId: hubspotId, // Use company_id for client
      };
    } else {
      dataToStore = {
        type: selectedDropDown.value,
        options: options,
        searchTerm: searchTerm, // Use searchTerm for other types
      };
    }

    // Store the data in localStorage
    localStorage.setItem('links-filter-storage', JSON.stringify(dataToStore));
    window.dispatchEvent(new Event('storage'));
    // Removed clearing of searchTerm
  };

  const handleClearFilter = () => {
    localStorage.removeItem('links-filter-storage');
    window.dispatchEvent(new Event('storage'));
    clearOptions();
    setSelectedOptions([]);
    setSearchTerm('');
    handleFetchLinkSearchItems();
  };

  useEffect(() => {
    if (selectedOptions.length > 0) {
      setShowDropdown(false);
    }
  }, [selectedOptions]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleFilterClick();
      handleFetchLinkSearchItems();
      // Removed clearing of searchTerm
    }
  };

  return (
    <div className="relative w-full">
      <div className="flex gap-0">
        <div className="w-fit h-fit border flex gap-2 py-[0.63rem] pl-[10px] text-xs rounded-tl-lg rounded-bl-lg dark:border-gray-600">
          {roleOptions.map((role, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedDropDown(role);
                handleClearFilter();
              }}
              className={`min-w-[60px] px-2 text-center cursor-pointer dark:text-white ${
                selectedDropDown.label === role.label
                  ? 'bg-[#D0EDFF] text-[#001C44] dark:bg-gray-500 font-[600] py-[0.5] rounded border-x'
                  : role.label === 'User'
                    ? 'border-x'
                    : ''
              }`}
            >
              {role.label}
            </div>
          ))}
          <div
            className="border-l px-1 cursor-pointer"
            onClick={() => {
              if (
                selectedDropDown.value !== 'user' &&
                selectedDropDown.value !== 'link'
              ) {
                setShowDropdown(!showDropdown);
              }
            }}
          >
            <Icon name="ChevronDown" size={16} />
          </div>
        </div>

        <div className="flex-grow">
          <div className="flex items-center w-full">
            <div className="relative">
              <input
                className={`block p-[0.5rem] w-full sm:w-[250px] z-50 text-sm text-gray-900 rounded-e-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600`}
                placeholder={`Search by ${selectedDropDown.value}`}
                value={searchTerm}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
              />
              {searchTerm.length > 0 ? (
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2.5 text-sm font-medium h-full text-white rounded-e-lg"
                  onClick={() => handleClearFilter()} // Clear the search term
                >
                  <Icon name="Xclose" size={16} />
                </button>
              ) : (
                <button
                  className="absolute right-0 top-1/2 transform -translate-y-1/2 p-2.5 text-sm font-medium h-full text-white rounded-e-lg"
                  onClick={() => handleFilterClick()}
                >
                  <Icon name="SearchRefractionIcon" size={16} />
                </button>
              )}
            </div>
          </div>

          {showDropdown &&
            selectedDropDown.value !== 'user' &&
            selectedDropDown.value !== 'link' && (
              <div className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1">
                <div className="w-full sm:w-[250px] max-h-[170px] overflow-y-scroll">
                  {dropDownOptions?.data?.map((option, index) => (
                    <div
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 text-[#000] cursor-pointer text-xs"
                      onClick={() => {
                        handleOptionClick(option);
                        if (
                          selectedDropDown.value !== 'user' &&
                          selectedDropDown.value !== 'link'
                        ) {
                          setShowDropdown(!showDropdown);
                        }
                      }}
                    >
                      {option.name ||
                        `${option.first_name} ${option.last_name}`}
                    </div>
                  ))}
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default LinksLookupSearch;
