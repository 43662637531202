import moment from 'moment';
import Icon from '../../assets/icons/SvgComponent';
import ImageIcon from '../../components/ImageIcon';
import Tooltip from '../../components/HelpTooltip';
import ToolTipV2 from '../../components/TooltipV2';
import MetricsChart from '../../components/Modals/Performance/metricsChart';
import { isStaging } from '../../utils';
import { getDeltaTypeAndColor } from '../../utils/performance';
import MetricView from '../../components/Performance/MetricView';
import { formatNumber } from '../../utils/common';

// Define the props for the generateColumns function
interface GenerateColumnsProps {
  sortingColumn: any;
  sortingDirection: any;
  performanceVisibilityOption: any;
  editClientPerformance: (data) => void;
  handleRegeneratePerformance: (ids, type) => void;
}

// Function to generate columns
export const generateMetaColumns = ({
  sortingColumn,
  sortingDirection,
  performanceVisibilityOption,
  editClientPerformance,
  handleRegeneratePerformance,
}: GenerateColumnsProps) => [
  // name column
  {
    id: 'name',
    header: () => (
      <div className="w-[180px] sm:w-[15vw] border-r dark:border-gray-700 h-[40px] flex items-center">
        <div className="flex w-[180px] sm:w-[15vw] justify-start items-center gap-2 cursor-pointer">
          <div className="text-left text-sm font-inter w-fit">Client</div>
          <div>
            {sortingColumn === 'name' ? (
              sortingDirection === 'asc' ? (
                <Icon name="Arrowup" />
              ) : sortingDirection === 'desc' ? (
                <Icon name="ArrowDown" />
              ) : (
                <Icon name="Sort" />
              )
            ) : (
              <Icon name="Sort" />
            )}
          </div>
        </div>
      </div>
    ),
    accessorKey: 'client',
    cell: (props: any) => {
      return (
        <div
          className="w-[180px] sm:w-[15vw] flex justify-start items-center cursor-pointer border-r dark:border-gray-700 h-[70px]"
          onClick={() => {
            if (props?.getValue() !== undefined) {
              editClientPerformance(props?.row?.original);
            }
          }}
        >
          <div>
            <Tooltip content={props.getValue()?.name} position="right">
              <div className="truncate overflow-hidden text-sm max-w-[180px] sm:max-w-[15vw] font-inter font-[500] text-[#001C44] dark:text-[#FFF]">
                {props.getValue()?.name}
              </div>
            </Tooltip>
            <div
              className={`text-left text-xs font-inter font-light text-[#405573] dark:text-gray-300 w-fit`}
            >
              {props.getValue()?.category_type === null
                ? 'N/A'
                : props.getValue()?.category_type}
            </div>
            {props.getValue()?.googleAds?.message && (
              <div
                className={`text-left text-xs font-inter font-light text-[#FF0000]  w-fit`}
              >
                {props.getValue()?.googleAds?.message}
              </div>
            )}
          </div>
        </div>
      );
    },
  },

  // revenue column
  {
    id: 'revenue',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Retainer</div>
          {sortingColumn === 'revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'revenue',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex justify-center font-inter font-[400] text-sm text-[#001C44] dark:text-gray-300">
          {props.getValue()}
        </div>
      </div>
    ),
  },

  // account manager column
  {
    id: 'account_manager',
    header: () => (
      <div className="w-[100px] sm:w-[8vw] ">
        <div className="flex w-[100px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">AM</div>
          {sortingColumn === 'account_manager' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'account_manager',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[8vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                      bg-${props.getValue().color}-500
                    `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // consultant column
  {
    id: 'consultant',
    header: () => (
      <div className="w-[100px] sm:w-[8vw] ">
        <div className="flex w-[100px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Cons</div>
          {sortingColumn === 'consultant' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'consultant',
    cell: (props: any) => {
      const tooltip = `Name: ${props.getValue().name}\nHsName: ${props.getValue().hsName}`;

      return (
        <div className="flex items-center gap-x-1 justify-center w-[100px] sm:w-[8vw] text-sm font-[500] font-inter">
          <ToolTipV2
            tooltip={`${props.getValue().name === undefined ? '' : tooltip}`}
          >
            <div className="relative cursor-default">
              <ImageIcon
                data={props.getValue().name || ''}
                size={7}
                textSize={'xs'}
                customStyle="bg-[#F3F7FA] text-[#7C7E7F] font-inter font-[600] border border-gray-200 rounded-full"
              />
              {props.getValue().name !== 'N/A' ? (
                <div
                  className={`absolute bottom-0 right-0 w-2.5 h-2.5 rounded-lg border border-white
                    bg-${props.getValue().color}-500
                  `}
                />
              ) : null}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },
  // start date column
  {
    id: 'start_date',
    header: () => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="flex w-[160px] sm:w-[8vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Start Date </div>
          {sortingColumn === 'start_date' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'startDate',
    cell: (props: any) => (
      <div className="w-[160px] sm:w-[8vw] ">
        <div className="text-sm flex justify-center font-inter font-[400] text-[#001C44] dark:text-gray-300">
          {moment(props?.getValue())?.format('MMM-YYYY')}
        </div>
      </div>
    ),
  },

  // performance column
  {
    id: 'performance',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Performance</div>
          {sortingColumn === 'performance' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'performance',
    cell: (props: any) => {
      const outOf = 11;
      const tooltip =
        `Impression Reach Frequency CPM LinkClicks CTR WebsiteLeads CPL CVR Revenue ROAS`
          .split(' ')
          .join('\n');

      const displayPercentage = props.getValue().value;

      let arrowColor;

      const handleDeltaType = () => {
        if (Number(displayPercentage) >= 75) {
          arrowColor = '#3CCB7F';
          return 'ArrowUpLeft';
        }
        if (Number(displayPercentage) >= 50) {
          arrowColor = '#FF692E';
          return 'ArrowRight';
        } else {
          arrowColor = '#FF0000';
          return 'ArrowDownLeft';
        }
      };

      return (
        <div className="flex flex-col items-center justify-center font-inter font-[600] w-[160px] sm:w-[12vw] ">
          <div
            className={`cursor-pointer flex gap-1 items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
          >
            <Icon name={handleDeltaType()} size={14} color={arrowColor} />
            <div className={`text-[${arrowColor}]`}>
              {formatNumber(displayPercentage)}%
            </div>
          </div>
          <ToolTipV2 tooltip={tooltip}>
            <div className="text-xs mt-1 cursor-pointer font-light text-[#405573] dark:text-gray-300">
              out of {outOf}
            </div>
          </ToolTipV2>
        </div>
      );
    },
  },

  // impressions column
  {
    id: 'meta.impressions',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Impressions</div>
          {sortingColumn === 'meta.impressions' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'impressions',
    cell: (props: any) => {
      const category = ['Impressions'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Impressions"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // reach column
  {
    id: 'meta.reach',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Reach</div>
          {sortingColumn === 'meta.reach' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'reach',
    cell: (props: any) => {
      const category = ['Reach'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Reach"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // frequency column
  {
    id: 'meta.frequency',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Frequency</div>
          {sortingColumn === 'meta.frequency' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'frequency',
    cell: (props: any) => {
      const category = ['Frequency'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Frequency"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // cpm column
  {
    id: 'meta.cpm',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CPM</div>
          {sortingColumn === 'meta.cpm' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'cpm',
    cell: (props: any) => {
      const category = ['CPM'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="CPM"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // link_clicks column
  {
    id: 'meta.link_clicks',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Link Clicks</div>
          {sortingColumn === 'meta.link_clicks' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'link_clicks',
    cell: (props: any) => {
      const category = ['Link Clicks'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Link Clicks"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // ctr column
  {
    id: 'meta.ctr',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CTR</div>
          {sortingColumn === 'meta.ctr' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'ctr',
    cell: (props: any) => {
      const category = ['CTR'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="CTR"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // website_leads column
  {
    id: 'meta.website_leads',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Website Leads</div>
          {sortingColumn === 'meta.website_leads' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'website_leads',
    cell: (props: any) => {
      const category = ['Website Leads'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Website Leads"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // cpl column
  {
    id: 'meta.cpl',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CPL</div>
          {sortingColumn === 'meta.cpl' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'cpl',
    cell: (props: any) => {
      const category = ['CPL'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="CPL"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // cvr column
  {
    id: 'meta.cvr',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">CVR</div>
          {sortingColumn === 'meta.cvr' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'cvr',
    cell: (props: any) => {
      const category = ['CVR'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="CVR"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // revenue column
  {
    id: 'meta.revenue',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">Revenue</div>
          {sortingColumn === 'meta.revenue' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'revenueMeta',
    cell: (props: any) => {
      const category = ['Revenue'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="Revenue"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // roas column
  {
    id: 'meta.roas',
    header: () => (
      <div className="w-[160px] sm:w-[12vw] ">
        <div className="flex w-[160px] sm:w-[12vw] justify-center items-center gap-2 cursor-pointer">
          <div className="text-sm font-inter">ROAS</div>
          {sortingColumn === 'meta.roas' ? (
            sortingDirection === 'asc' ? (
              <Icon name="Arrowup" />
            ) : sortingDirection === 'desc' ? (
              <Icon name="ArrowDown" />
            ) : (
              <Icon name="Sort" />
            )
          ) : (
            <Icon name="Sort" />
          )}
        </div>
      </div>
    ),
    accessorKey: 'roas',
    cell: (props: any) => {
      const category = ['ROAS'];
      const { deltaType, color } = getDeltaTypeAndColor(
        props.getValue()?.current,
        props.getValue()?.previous
      );

      return (
        <div className="w-[160px] sm:w-[12vw]">
          <div className="flex flex-col justify-center items-center gap-1 text-xs font-inter font-[600]">
            {!props.getValue()?.message ? (
              <>
                <MetricsChart
                  tooltip={props.getValue()?.dailyMetrics}
                  category={category}
                  legendName="ROAS"
                  clientName={props.getValue()?.clientName}
                  position="right"
                >
                  <div
                    className={`cursor-pointer flex gap-1 justify-center items-center h-[19px] text-sm p-[6px] border-0 bg-gray-100 rounded`}
                  >
                    <Icon name={deltaType} size={14} color={color} />
                    <div className={`text-[${color}]`}>
                      {props.getValue()?.current}
                    </div>
                  </div>
                </MetricsChart>
                <MetricView
                  color={color}
                  previous={props.getValue()?.previous}
                />
              </>
            ) : (
              <Tooltip content={props.getValue()?.current} position="right">
                <div
                  className={`p-2 cursor-pointer flex gap-1 items-center h-[19px] text-sm max-w-[160px] sm:max-w-[12vw] border-0 bg-gray-100 rounded truncate`}
                >
                  <div className={`text-[${color}]`}>Error</div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      );
    },
  },

  // Actions column
  {
    id: 'Action',
    header: () => (
      <div className="flex justify-center w-[100px] sm:w-[8vw] text-sm font-inter">
        Action
      </div>
    ),
    cell: (cell: any) => {
      return (
        <div className="flex space-x-3 items-center  w-[100px] sm:w-[8vw] justify-center ">
          <div
            className="cursor-pointer"
            onClick={() => {
              editClientPerformance(cell?.row?.original);
            }}
          >
            <Icon name="edit" />
          </div>
          {isStaging() ? (
            <div
              className="cursor-pointer"
              onClick={() => {
                handleRegeneratePerformance(
                  cell?.row.original?.client?.client,
                  'social_meta'
                );
              }}
            >
              <Icon name="RefreshIcon" size={28} color="#7C7E7F" />
            </div>
          ) : null}
        </div>
      );
    },
  },
];
