import { create } from 'zustand';
// import Api from '../api';
import { devtools } from 'zustand/middleware';
import Api from '../api';

interface IGoalStore {
  goals: any;
  goalsGraph: any;
  message: '';
  error: false;
  loading: false;
  errorMessages: '';
  fetchGoals: (data: any) => void;
  fetchGoalsGraph: (data: any) => void;
  syncGoal: (data: any) => void;
}

const useGoalStore = create<IGoalStore>(
  devtools((set) => ({
    goals: [],
    goalsGraph: [],
    message: '',
    error: false,
    loading: false,
    errorMessages: '',
    fetchGoals: async (data) => {
      set(() => ({ loading: true }));
      const response = await Api.Goal.getGoals(data);
      set(() => ({ goals: response, loading: false }));
    },
    fetchGoalsGraph: async (data) => {
      set(() => ({ loading: true }));
      const response = await Api.Goal.getGoalsGraphs(data);
      set(() => ({ goalsGraph: response, loading: false }));
    },
    syncGoal: async (data) => {
      const response = await Api.Goal.sync(data);
      return response;
    },
  })) as any
);

export default useGoalStore;
