import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import TanStackTable from '../../components/TanStackTable';
import Icon from '../../assets/icons/SvgComponent';
import { useThemeStore } from '../../store/useThemeStore';
import IndeterminateCheckbox from '../../components/IndeterminateCheckbox';
import useClientStore from '../../store/useClientStore';
import ImageIcon from '../../components/ImageIcon';
import CustomModal from '../../components/CustomModal';
import EditModal from '../../components/Modals/Customer/editModal';
import ConfirmStatus from '../../components/Modals/UserModal/confirmationStatus';
import Alert from '../../components/Alert';
import BulkActionModal from '../../components/Modals/UserModal/bulkActionModal';
import ReactSelect from '../../components/ReactSelect';
import Tooltip from '../../components/HelpTooltip';
import { ToastContainer, toast, Flip } from 'react-toastify';
import { typeOptions } from '../../components/ReportTanStackTable/staticData';
import SearchBar from '../../components/Search';
import { getRoleName } from '../../utils';
import ExportModal from '../../components/Modals/Customer/Export';

export default function Customers() {
  const navigate = useNavigate();
  const location = useLocation();
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const { fetchClients, clients, loading, apiStatus, updateClientStatus } =
    useClientStore((state: any) => state);
  const [searchText, setSearchText] = useState('');
  const [confirmStatus, setConfirmStatus] = useState({});
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [selectedType, setSelectedType] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [selectedService, setSelectedService] = useState<{
    label: string;
    value: string;
  } | null>({ label: 'All Services', value: 'all' });
  const [selectedIntegration, setSelectedIntegration] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [page, setPage] = useState(1);
  const isServiceSelected = selectedService?.value !== 'all';
  const [limit, setLimit] = useState({ label: '20', id: '20' });
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowState, setRowState] = useState(true);
  const [bulkActionModal, setBulkActionModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);

  const serviceOptions = typeOptions.map((option) => ({
    value: option.id,
    label: option.label,
  }));
  const [selectedStatus, setSelectedStatus] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [isMounted, setisMounted] = useState(false);

  const statusOptions = [
    { label: 'All Status', value: 'all' },
    { label: 'My Clients', value: 'My Clients' },
    { label: 'Current', value: 'Current Client' },
    { label: 'Paused', value: 'Paused' },
    { label: 'Past', value: 'Past Client' },
    { label: 'Lead', value: 'Lead' },
    { label: 'Lost Lead', value: 'Lost Lead' },
    { label: 'Referral Partner', value: 'Referral Partner' },
    { label: 'Deleted', value: 'deleted' },
  ];

  const typesOptions = [
    { label: 'All Type', value: 'all' },
    { label: 'Lead Gen', value: 'Lead Gen' },
    { label: 'E-Commerce', value: 'Ecommerce' },
    { label: 'Varied', value: 'Varied' },
    { label: 'Whitelabel', value: 'Whitelabel' },
  ];

  const reportOptions = [
    { label: 'All Reports', value: 'all' },
    { label: 'Missing Integration', value: 'missing_integration' },
    { label: 'Integrated', value: 'integrated' },
  ];

  useEffect(() => {
    if (searchText?.length === 0 && isMounted) {
      handleFetchClients(page);
    }
  }, [searchText]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const statusFromUrl = queryParams.get('status');
    const typeFromUrl = queryParams.get('type');
    const serviceFromUrl = queryParams.get('service');
    const reportFromUrl = queryParams.get('report');

    const foundStatusOption = statusOptions.find(
      (option) => option.value === statusFromUrl
    );
    const foundTypeOption = typesOptions.find(
      (option) => option.value === typeFromUrl
    );
    const foundServiceOption = serviceOptions.find(
      (option) => option.value === serviceFromUrl
    ) || { label: 'All Services', value: 'all' };
    const foundReportOption = reportOptions.find(
      (option) => option.value === reportFromUrl
    );
    setSelectedStatus(foundStatusOption);
    setSelectedType(foundTypeOption);
    setSelectedService(foundServiceOption);
    setSelectedIntegration(foundReportOption);
    setisMounted(true);
  }, [location]);

  useEffect(() => {
    if (isMounted) {
      handleFetchClients(page);
    }
  }, [
    selectedStatus,
    limit,
    apiStatus,
    selectedType,
    selectedService,
    selectedIntegration,
    page,
  ]);

  const handleStatusChange = (selectedOption: any, actionMeta: any) => {
    const newUrl = new URL(window.location.href);
    if (actionMeta === 'status') {
      setSelectedStatus(selectedOption);
      newUrl.searchParams.set('status', selectedOption.value);
    }
    if (actionMeta === 'type') {
      setSelectedType(selectedOption);
      newUrl.searchParams.set('type', selectedOption.value);
    }
    if (actionMeta === 'service') {
      setSelectedService(selectedOption);
      newUrl.searchParams.set('service', selectedOption.value);
    }
    if (actionMeta === 'report') {
      setSelectedIntegration(selectedOption);
      newUrl.searchParams.set('report', selectedOption.value);
    }

    window.history.pushState({}, '', newUrl);
  };

  const handleFetchClients = ({ currentPage }: any) => {
    let filters = {
      status: selectedStatus?.value,
      search: searchText,
      page: currentPage || page,
      limit: limit.id,
      category_type: selectedType?.value,
      service: selectedService?.value,
      integration: selectedIntegration?.value,
    };

    fetchClients(filters);
    if (currentPage) {
      setPage(currentPage);
    }
  };

  const formatData = (data: any) => {
    if (!data || !Array.isArray(data)) return null;

    const customerData = data.map((customer: any) => {
      const accountManagerAm = customer.users?.find(
        (manager: any) => manager.gurulytics_role === 'am'
      );
      const consultantArray = customer.users?.filter((manager: any) =>
        manager.gurulytics_role.includes('con')
      );
      return {
        id: customer.id,
        customerDetails: customer,
        domain: customer.domain,
        campaignTypeAndCycle: customer.category_type,
        consultant: consultantArray,
        accountManager: accountManagerAm,
        services: customer.service,
        isEnabled: customer,
        action: customer,
      };
    });

    return customerData;
  };

  const formattedDataMemo = useMemo(() => {
    const formattedData = formatData(clients.data);
    return formattedData;
  }, [clients.data]);

  const handleToggleStatus = (id: string, currentStatus: string) => {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
    updateClientStatus({ ids: id, status: newStatus });
    toast.success(
      <Alert
        icon=""
        title={`CLIENTS STATUS`}
        message={`Client's status been changed successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setConfirmationStatus(false);
    setConfirmStatus({});
    setTimeout(() => {
      handleFetchClients(page);
    }, 1000);
  };

  const handleBulkEnable = () => {
    const result: string[] = selectedRow.map((user: any) => {
      return user.id;
    });
    updateClientStatus({ ids: result, status: 'active' });
    setRowState(false);
    toast.success(
      <Alert
        icon=""
        title={`STATUS UPDATE`}
        message={`Client's status has been set to "active" successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setTimeout(() => {
      handleFetchClients(page);
    }, 500);
    setSelectedRow([]);
    setBulkActionModal(false);
    setModalType('');
  };

  const handleBulkDisable = () => {
    const result: string[] = selectedRow.map((user: any) => {
      return user.id;
    });
    updateClientStatus({ ids: result, status: 'inactive' });
    setRowState(false);
    toast.success(
      <Alert
        icon=""
        title={`STATUS UPDATE`}
        message={`Client's status has been set to "inactive" successfully`}
      />,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
    setTimeout(() => {
      handleFetchClients(page);
    }, 500);
    setSelectedRow([]);
    setBulkActionModal(false);
    setModalType('');
  };

  const removeUser = (userId: string) => {
    const updatedSelectedRow = selectedRow.filter(
      (user: any) => user.id !== userId
    );
    setSelectedRow(updatedSelectedRow);
  };

  const columns = [
    {
      id: 'Select',
      header: ({ table }: any) => {
        return (
          <div className="flex items-center justify-center w-fit ml-3">
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllPageRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          </div>
        );
      },
      cell: ({ row }: any) => {
        return (
          <div className="flex items-center justify-center w-fit ml-3">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      id: 'Client Name',
      header: () => (
        <div className="flex justify-start w-[180px] sm:w-[15vw] pl-2 text-sm font-[600] font-inter">
          Client Name
        </div>
      ),
      accessorKey: 'customerDetails',
      cell: (props: any) => (
        <div
          onClick={() =>
            navigate(`/client/details/users/${props?.getValue()?.id}`, {
              state: props?.getValue(),
            })
          }
          className="w-[180px] sm:w-[15vw] flex justify-start pl-2 cursor-pointer"
        >
          <div className="text-sm font-semibold text-gray-500 dark:text-white font-inter">
            <Tooltip position="right" content={props.getValue().name}>
              <div className="truncate overflow-hidden max-w-[160px] sm:max-w-[15vw]">
                {props.getValue().name}
              </div>
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      id: 'Domain',
      header: () => (
        <div className="text-left w-[100px] sm:w-[10vw] text-sm font-[600] font-inter">
          Domain
        </div>
      ),
      accessorKey: 'domain',
      cell: (props: any) => (
        <div className="w-[100px] sm:w-[10vw] flex justify-start">
          <Tooltip position="right" content={`https://${props.getValue()}`}>
            <a href={`https://${props.getValue()}`} target="blank">
              <div className="text-sm font-[600] text-gray-500 truncate overflow-hidden w-[90px] sm:w-[10vw] text-left dark:text-white font-inter">
                {props.getValue()}
              </div>
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      id: 'Campaign Type',
      header: () => (
        <div className="text-center w-[140px] sm:w-[10vw] text-sm font-[600] font-inter">
          Campaign Type
        </div>
      ),
      accessorKey: 'campaignTypeAndCycle',
      cell: (props: any) => (
        <div className="flex items-center gap-2 justify-center dark:text-white font-inter w-[140px] sm:w-[10vw]">
          {props.getValue() ? props.getValue() : 'N/A'}
        </div>
      ),
    },
    {
      id: 'Consultant',
      header: () => (
        <div className="text-center w-[100px] sm:w-[10vw] text-sm font-[600] font-inter">
          Consultant
        </div>
      ),
      accessorKey: 'consultant',
      cell: (props: any) => {
        const consultants = props.getValue();

        return (
          <div className="flex justify-center font-inter w-[100px] sm:w-[10vw]">
            <div className="rounded-full font-inter flex justify-center">
              {consultants.length > 0 ? (
                consultants.map((consultant, index) => (
                  <div key={index}>
                    <Tooltip
                      position="left"
                      content={`Name: ${consultant?.first_name} ${consultant?.last_name}  
                      Role: ${getRoleName(consultant?.gurulytics_role)}`}
                    >
                      <div
                        className={`${index === 0 ? '' : 'ml-[-6px]'} border-white border rounded-full dark:border-gray-700`}
                      >
                        <ImageIcon
                          data={`${consultant?.first_name} ${consultant?.last_name}`}
                          size={8}
                          textSize={'xs'}
                        />
                      </div>
                    </Tooltip>
                  </div>
                ))
              ) : (
                <div>N/A</div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      id: 'Account Manager',
      header: () => (
        <div className="text-center w-[140px] sm:w-[10vw] text-sm font-[600] font-inter">
          Account Manager
        </div>
      ),
      accessorKey: 'accountManager',
      cell: (props: any) => {
        const data = {
          name: `${props.getValue()?.first_name} ${props.getValue()?.last_name}`,
          profile_pic: props.getValue()?.profile_pic,
        };

        if (data.name === 'undefined undefined') {
          return (
            <div className="dark:text-white w-[140px] sm:w-[10vw] text-center">
              N/A
            </div>
          );
        }

        return (
          <div className="flex justify-center font-inter w-[140px] sm:w-[10vw]">
            <div className={`rounded-full font-inter flex justify-center`}>
              <Tooltip
                position="left"
                content={`Name: ${props.getValue()?.first_name} ${props.getValue()?.last_name}
                          Role: ${getRoleName(props.getValue()?.gurulytics_role)}`}
              >
                <ImageIcon data={data} size={8} textSize={'xs'} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
    {
      id: 'isEnabled',
      header: () => (
        <div className="text-center sm:text-left w-[70px] sm:w-[5vw]">
          Status
        </div>
      ),
      accessorKey: 'isEnabled',
      cell: (props: any) => (
        <label className="relative inline-flex items-center  cursor-pointer font-inter w-[70px] sm:w-[5vw] ml-4 lg:ml-[2px]">
          <input
            type="checkbox"
            className="sr-only peer"
            defaultChecked={props.getValue()?.status === 'active'}
            value={props.getValue()?.status}
            onClick={() => {
              setConfirmStatus(props.getValue());
              setConfirmationStatus(true);
            }}
          />
          <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
        </label>
      ),
    },
    {
      id: 'Action',
      header: () => (
        <div className="text-center w-[180] sm:w-[5vw] text-sm font-[600] font-inter">
          Action
        </div>
      ),
      accessorKey: 'action',
      cell: (cell: any) => (
        <div className="flex gap-4 justify-center cursor-pointer font-inter w-[180] sm:w-[5vw]">
          <div
            onClick={() => {
              setSelectedCustomer(cell.getValue());
              setOpenEditModal(true);
            }}
          >
            <Icon
              name="UserEditIcon"
              color={mode === 'dark' ? '#fff' : iconColor}
            />
          </div>
          <div>
            <div
              onClick={() =>
                navigate(`/client/details/users/${cell?.getValue()?.id}`, {
                  state: cell?.getValue(),
                })
              }
            >
              <Icon
                name="Settings"
                size={20}
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const handleSearch = () => {
    try {
      handleFetchClients(1);
      setPage(1);
      setRowState(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="bg-[#D2EEFF] mt-12 px-2 sm:mt-0 sm:py-3 sm:px-[32px] lg:overflow-hidden min-h-[60dvh] lg:min-h-[93dvh] dark:bg-[#0C111D] font-inter rounded-lg">
      <div className="bg-[#D2EEFF] pt-2 sm:pt-0 rounded-lg dark:bg-[#0C111D] ">
        <div className="flex items-center justify-between mt-4 px-2 py-4 sm:mt-0 sm:pt-14 lg:p-4 bg-white dark:bg-gray-800 lg:sticky top-0 z-10 rounded-t-lg">
          <div className="flex items-center flex-wrap gap-2">
            <SearchBar
              value={searchText}
              onChange={setSearchText}
              onSearch={handleSearch}
              placeholder="Search clients by name or domain "
              width="w-[220px] sm:w-[300px]"
            />
            <div>
              <ReactSelect
                options={statusOptions}
                value={selectedStatus}
                placeholder="All Status"
                handleOnChange={(selectedOption: any) =>
                  handleStatusChange(selectedOption, 'status')
                }
              />
            </div>

            <div>
              <ReactSelect
                options={typesOptions}
                value={selectedType}
                placeholder="All Type"
                handleOnChange={(selectedOption: any) =>
                  handleStatusChange(selectedOption, 'type')
                }
              />
            </div>
            <div>
              <ReactSelect
                options={serviceOptions}
                placeholder="All Services"
                value={selectedService}
                handleOnChange={(selectedOption: any) =>
                  handleStatusChange(selectedOption, 'service')
                }
              />
            </div>
            <button
              className="p-2 rounded-md border block sm:hidden"
              onClick={() => setShowExportModal(true)}
            >
              <Icon
                name="Download"
                size={16}
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </button>
            {isServiceSelected && (
              <div>
                <ReactSelect
                  options={reportOptions}
                  isDisabled={selectedService?.value === 'all' ? true : false}
                  placeholder="All"
                  value={selectedIntegration}
                  handleOnChange={(selectedOption: any) =>
                    handleStatusChange(selectedOption, 'report')
                  }
                />
              </div>
            )}
          </div>
          <div className="flex gap-2 hidden sm:block">
            {selectedRow.length !== 0 && (
              <div className="flex gap-2 justify-center  items-center">
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setRowState(true);
                      setBulkActionModal(true);
                      setModalType('Enable');
                    }}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#005c9f] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Enable
                    <Icon name="Submit" />
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setRowState(true);
                      setBulkActionModal(true);
                      setModalType('Disable');
                    }}
                    className="rounded-8 text-sm flex items-center gap-2 bg-[#d92d20] py-[8px] px-[14px] rounded-md min-w-100px cursor-pointer text-white font-inter"
                  >
                    Disable
                    <Icon name="Cancel" />
                  </button>
                </div>
              </div>
            )}
            <button
              className="p-2 rounded-md border"
              onClick={() => setShowExportModal(true)}
            >
              <Icon
                name="Download"
                size={16}
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </button>
            {/* <div>
              <ExportButton
                data={!formattedDataMemo ? [] : formattedDataMemo}
                headers={[
                  'customerDetails',
                  'domain',
                  'campaignTypeAndCycle',
                  'accountManager',
                  'services',
                ]}
                filename="clientData"
              />
            </div> */}
          </div>
        </div>
        <TanStackTable
          data={!formattedDataMemo ? [] : formattedDataMemo}
          columns={columns}
          totalPages={!clients.total_pages ? 0 : clients.total_pages}
          page={page}
          setPage={setPage}
          rowState={rowState}
          setRowState={setRowState}
          loading={loading}
          setSelectedRow={setSelectedRow}
          totalDocs={clients?.total_docs}
          tableHeight="h-[64dvh] sm:h-[69.8dvh]"
        />
        <CustomModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
        >
          <EditModal
            user={selectedCustomer}
            onClose={() => setOpenEditModal(false)}
          />
        </CustomModal>
      </div>
      {modalType === 'Enable' ? (
        <CustomModal
          open={bulkActionModal}
          onClose={() => setBulkActionModal(false)}
        >
          <BulkActionModal
            type="enable"
            user={selectedRow}
            onClose={() => setBulkActionModal(false)}
            action={handleBulkEnable}
            onRemoveUser={removeUser}
          />
        </CustomModal>
      ) : modalType === 'Disable' ? (
        <CustomModal
          open={bulkActionModal}
          onClose={() => setBulkActionModal(false)}
        >
          <BulkActionModal
            type="disable"
            user={selectedRow}
            onClose={() => setBulkActionModal(false)}
            action={handleBulkDisable}
            onRemoveUser={removeUser}
          />
        </CustomModal>
      ) : null}
      <CustomModal
        open={confirmationStatus}
        onClose={() => setConfirmationStatus(false)}
      >
        <ConfirmStatus
          user={confirmStatus}
          onClose={() => setConfirmationStatus(false)}
          action={handleToggleStatus}
        />
      </CustomModal>
      <CustomModal
        open={showExportModal}
        onClose={() => {
          setShowExportModal(false);
        }}
      >
        <ExportModal
          closeModal={() => {
            setShowExportModal(false);
          }}
          status={selectedStatus}
          type={selectedType}
          service={selectedService}
          integration={selectedIntegration}
        />
      </CustomModal>
      <ToastContainer
        position="top-right"
        autoClose={30000}
        hideProgressBar
        newestOnTop={false}
        transition={Flip}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}
